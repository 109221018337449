export default {
    methods: {
        deepCopy (obj) {
            return JSON.parse(JSON.stringify(obj))
        },

        annotationObject (key, value, orientation, color) {
            let axis = 'x-axis-0'
            if (orientation === 'horizontal') axis = 'y-axis-0'
            return {
                type: 'line',
                drawTime: 'afterDatasetsDraw',
                id: 'a-line-' + key,
                mode: orientation,
                scaleID: axis,
                value: value,
                borderColor: color,
                borderWidth: 2,
                label: {
                    backgroundColor: 'rgba(0,0,0,0.8)',
                    fontSize: 12,
                    fontStyle: "bold",
                    fontColor: "#fff",
                    xPadding: 5,
                    yPadding: 5,
                    cornerRadius: 5,
                    // Anchor position of label on line, can be one of: top, bottom, left, right, center. Default below.
                    position: "top",
                    xAdjust: 0,
                    yAdjust: 0,
                    enabled: false,
                    content: key
                },
                events: ['click','mouseover','mouseleave'],
            }
        },
        getLevelColors (index) {
            return ['red', 'blue', 'yellow', 'green'][index]
        },
        // returns Excelled, Very Good, Good, Bad, Very Bad
        // based on index
        getSuccessStrings(index) {
            return ['Very Bad', 'Bad', 'Good', 'Very Good', 'Excelled'][index]
        },
        // checks if a series of values is ascending, descending or none of two
        // params:
        //  * array of values
        isMonotonic (values) {
            if (values.includes(undefined) || values.includes(null)) return 'null'

            let monotonous = true
            let previousDifference = 0
            for (let i = 0; i < values.length - 1; i++) {
                const currentDifference = values[i + 1] - values[i]
                if (i > 0) {
                    if (previousDifference === 0) return 'error'
                    if (currentDifference * previousDifference < 0) return 'error'
                }
                if (currentDifference > 0) previousDifference = 1
                else if (currentDifference < 0) previousDifference = -1
                else previousDifference = 0
            }
            if (previousDifference > 0) return 'asc'
            else return 'desc'
        },
        // when there are only a few psis then the bars are too large
        // so the height should be smaller in order for the graph to look better
        // compute aspect ratio based on the number of the psis
        psiChartAspectRatio (size) {
            if (size <= 2) return { width: 10, height: 2 }
            else if (size <= 4) return { width: 5, height: 2}
            else if (size <= 6) return { width: 4, height: 2}
            else if (size <= 10) return { width: 4, height: 2}
            else return { width: 1, height: 2}
        },
        spiChartAspectRatio (size) {
            if (size <= 2) return { width: 10, height: 2 }
            else if (size <= 4) return { width: 5, height: 2}
            else if (size <= 6) return { width: 4, height: 2}
            else if (size <= 10) return { width: 4, height: 2}
            else return { width: 1, height: 2}
        }
        
    },
};