<template>
  <div>
    <PsiForm
      v-model="form"
      :project-id="projectId"
      :psi-types="psiTypes"
      @submit="submit"
    ></PsiForm>
  </div>
</template>

<script>
import MainLayout from '@/Layouts/Main'
import PsiForm from './Form'
export default {
  name: "Edit",
  layout: MainLayout,
  components: {
    PsiForm
  },
  props: {
    projectId: {
      type: Number,
      required: true
    },
    psiId: {
      type: Number,
      required: true
    },
    psi: {
      type: Object,
      required: true
    },
    psiTypes: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      form: this.psi
    }
  },
  methods: {
    submit () {
      this.$inertia.put(this.$routes.project_psi(this.projectId, this.psiId), this.form)
    }
  }
}
</script>

<style scoped>

</style>