<template>
    <div>
        <div class="">{{ selectPrompt }}:</div>
        <b-form-select
                v-model="data"
                :options="availableItems"
                @change="updateSelectedData"
        ></b-form-select>
    </div> 
</template>
<script>
    export default {
        props: {
             selectPrompt: {
                type: String,
                required: false,
                default: 'Please select an item from the list'
            },
            availableItems: {
                type: Array,
                required: true
            },
            selectedItem: {
                type: String,
                required: false
            }
        },
        data(){
            return{
                data: null
            }
        },
     
        methods: {
            updateSelectedData(){
                this.$emit('update-data', this.data);
                
            }
        },
        watch: {
            selectedItem(){
                this.data = this.$props.selectedItem
            }
        }
    }
</script>

<style scoped>

</style>