<template>
  <div>
    <h1 style="text-align: center; margin-bottom: 10px;">New KPI Creation</h1>
    <KpiForm
      v-model="form"
      :project-id="projectId"
      :prototypes="prototypes"
      :kpi-types="kpiTypes"
      :accumulated-weights="accumulatedWeights"
      :default-kpi-values="defaultKpiValues"
      :ignored-weights="ignoredWeights"
      @submit="submit"
      @save="saveAndExit"
    ></KpiForm>
  </div>
</template>

<script>
import MainLayout from '@/Layouts/Main'
import KpiForm from './Form'
export default {
  name: "New",
  layout: MainLayout,
  components: {
    KpiForm
  },
  props: {
    projectId: {
      type: Number,
      required: true
    },
    kpi: {
      type: Object,
      required: true
    },
    sectors: {
      type: Array,
      required: true
    },
    sectorPrototype: {
      type: Object,
      required: true
    },
    dimension: {
      type: Object,
      required: true
    },
    aggregationLevelResults: {
      type: Array,
      required: true
    },
    aggregationPrototype: {
      type: Object,
      required: true
    },
    kpiTypes: {
      type: Array,
      required: true
    },
    defaultKpiValues: {
      type: Object,
      required: true
    },
    accumulatedWeights: {
      type: Object,
      required: false,
      default: null
    },
    ignoredWeights: {
      type: Array,
      required: false,
      default: function () {
        return []
      }
    }
  },
  data() {
    return {
      form: {
        kpi: this.kpi,
        sectors: this.sectors,
        dimension: this.dimension,
        aggregationLevels: []
      },
      prototypes: {
        sector: this.sectorPrototype,
        aggregation: this.aggregationPrototype
      }
    }
  },
  methods: {
    submit() {
      this.createSubmitObject(true)
      this.$inertia.post(this.$routes.project_kpis(this.projectId), this.form)
    },
    saveAndExit() {
      this.createSubmitObject(false)
      this.$inertia.post(this.$routes.project_kpis(this.projectId), this.form)
    },
    createSubmitObject (complete=true) {
      const sectors = this.form.kpi.sectors_attributes
      const levels = this.form.aggregationLevels
      const levelsAttrs = this.form.kpi.aggregation_levels_attributes
      sectors.forEach(function callbackFn(sector, index) {
        sector.aggregation_level_results_attributes = []
        const sectorKey = `sector_${sector.name}_weight`
        levels.forEach(function callbackFn(level, index) {
          const aggObj = {
            name: level.name,
            value: level.value,
            weight: level[sectorKey]
          }
          sector.aggregation_level_results_attributes.push(aggObj)
        })
      })

      levels.forEach(function callbackFn(level, index) {
        const aggObj = {
          name: level.name,
          value: level.value,
        }
        levelsAttrs.push(aggObj)
      })

      const dimension = this.form.kpi.dimension_attributes
      dimension.aggregation_level_results_attributes = []
      if (dimension.name) {
        levels.forEach(function callbackFn(level, index) {
          const aggObj = {
            name: level.name,
            value: level.value,
            weight: level.dimension_weight
          }
          dimension.aggregation_level_results_attributes.push(aggObj)
        })
      }
      // add state
      this.form.kpi.state = complete
    }
  }
}
</script>

<style scoped>

</style>
