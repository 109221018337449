<template>
  <div class="">
    <div class="mb-4">
      <inertia-link
        class="flex items-center group py-3"
        :href="$routes.root()"
        role="navigation"
        arial-label="Dashboard"
      >
        <div class="text-white">
          Dashboard
        </div>
      </inertia-link>
    </div>

    <div class="mb-4">
      <inertia-link
        class="flex items-center group py-3"
        :href="$routes.notes()"
        role="navigation"
        arial-label="Dashboard"
      >
        <div class="text-white">
          Notes
        </div>
      </inertia-link>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
</style>
