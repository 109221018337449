<script>
import { HorizontalBar, mixins } from 'vue-chartjs'
import AnnotationPlugin from "chartjs-plugin-annotation";
const { reactiveProp } = mixins

export default {
  name: "BarChart",
  extends: HorizontalBar,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    aspectRatio: {
      type: Object,
      required: false,
      default: null
    }
  },
  mounted () {
    Chart.plugins.register(AnnotationPlugin)

    if (this.aspectRatio) {
      this.$refs.canvas.width = this.aspectRatio.width
      this.$refs.canvas.height = this.aspectRatio.height
    }

    this.addPlugin([AnnotationPlugin])
    this.renderChart(this.chartData, this.options)
  }
}
</script>

<style scoped>

</style>