<template>
  <div>
    <h2>Creation of new PSI</h2>
    <div class="manual-text-container">
      <p>
        The user provides each PSI through the tab “New PSI Creation”, as shown in Figure 1 below, along with its
        measured
        value during the project and the associated call impact number. The Functional Unit (FU) of the PSI is also
        shown,
        which is the unit of measurement for the specific PSI (either default or user-defined) that has been chosen such
        that to “support fair comparability and benchmarking between two or more systems”.
      </p>

      <div class="image-container">
        <img src="@/images/manual/psi_step_1.png" alt="creation of new psi">
        <div class="img-caption">Figure 1. PSI creation page – step 1: PSI name, value and call impact number.</div>
      </div>

      <p>
        As a next step the user provides the target value of the PSI along with its margins (either default or
        user-defined), which define 5 intervals: “failed”, “Underachieved”, “Achieved”, “Overachieved” and “Excelled”,
        as shown in Figure 2 below. These intervals are used to calculate the score of the PSI (during the analysis
        phase), which takes values from 1 to 5 based on which interval its value lies (see example below). The “use
        relative margins?” tick box is clicked when the margins are expressed as percentage (%) of the target value. In
        the presented example the value of the PSI is equal to 25 “#/€ 1M of investments” (Figure 1) and since the
        relative margins tick box is clicked it should be divided by the target value of 50 in order to find in which
        interval it lies. In this case 25 divided by 50 is equal to 50% therefore the PSI lies in the “Excelled”
        interval (≥50%) getting a score of 5.
      </p>

      <div class="image-container">
        <img src="@/images/manual/psi-step-2.png" alt="creation of new psi second step">
        <div class="img-caption">Figure 2. PSI creation page – step 2: target value and margins.</div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "NewPsi"
}
</script>

<style scoped>

</style>