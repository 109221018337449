<template>
  <div v-if="show">
    <div v-if="$page.props.flash.success">
      <b-alert
        variant="success"
        dismissible
      >
        <p>{{ $page.props.flash.success }}</p>
      </b-alert>
    </div>

    <div v-if="$page.props.flash.alert">
      <b-alert v-model="showDismissableAlert" variant="warning" dismissible>
        {{ $page.props.flash.alert }}
      </b-alert>
    </div>

    <div v-if="Object.keys($page.props.errors).length > 0">
      <b-alert v-model="showDismissableAlert" variant="danger" dismissible>
        {{ $page.props.flash.errors }}
      </b-alert>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        show: true,
        showDismissableAlert: true,
      }
    },
    watch: {
      '$page.props.flash': {
        handler() {
          this.show = true,
          this.showDismissableAlert = true
        },
        deep: true
      },
    },
    methods: {
      capitalize(word) {
        return word.charAt(0).toUpperCase() + word.slice(1)
      },
    }
  }
</script>
