<template>
  <b-row>
    <h2 class="text-center">Projects Comparison</h2>

    <b-col cols="12" v-if="errors">
      <b-list-group class="mb-2">
        <b-list-group-item variant="danger">
          {{ errors }}
        </b-list-group-item>
      </b-list-group>
    </b-col>
    <b-col cols="12" v-if="siiChartData">
      <SimpleVerticalBarChart
          v-if="siiChartData"
          :chartData="siiChartData"
          :gradientColors="[]"
          :showColorCode="false"
          :showChartLegend="true"
          :axisLabels="{ x: 'Score', y: 'Aggregation Level' }"
          title="Projects Comparison"
      >
      </SimpleVerticalBarChart>
    </b-col>

  </b-row>
</template>

<script>
import MainLayout from '@/Layouts/Main'
import SimpleVerticalBarChart from "@/Charts/simple/SimpleVerticalBarChart";

export default {
  name: "ProjectsCompare",
  layout: MainLayout,
  components: {
    SimpleVerticalBarChart
  },
  props: {
    siiChartData: {
      type: Object,
      required: false
    },
    errors: {
      type: String,
      required: false
    }
  }
}
</script>

<style scoped>

</style>