export default {
    methods: {
        clearAndCapitalize (str) {
            if (['ped', 'peb'].includes(str)) return str.toUpperCase()
            const words = str.split("_")

            for (let i = 0; i < words.length; i++) {
                words[i] = words[i][0].toUpperCase() + words[i].substr(1)
            }

            return words.join(" ")
        },
        capitalize (str) {
            if (['ped', 'peb'].includes(str)) return str.toUpperCase()
            return str.charAt(0).toUpperCase() + str.slice(1)
        },
        isEmptyString (str) {
            return (str === null || str === "")
        },
        dateWithoutTime(date) {
            return `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`
        }
    }
};