<template>
  <div>
    <h2>Creation of New KPI</h2>
    <div class="manual-text-container">
      <p>
        The creation of a new KPI by the user involves a 4-step process. In the first step the user gives the name of
        the KPI, its aggregation levels (spatial scale of evaluation e.g. building, city, district) as well as the value
        of the KPI in each aggregation level measured in the functional unit of the KPI (similar to the section “PPI
        analysis result”), as shown in Figure 5. If the FU is Likert scale, then the KPI takes values ranging from 1 to
        5, which is identical to its score, and therefore no margins are needed (step 4).
      </p>

      <div class="image-container">
        <img src="@/images/manual/kpi-step-1.png" alt="psis index page">
        <div class="img-caption">Figure 5. KPI creation page – step 1: KPI name, aggregation levels and values.</div>
      </div>

      <p>
        In the 2<sup>nd</sup> step (Figure 6) the user provides the single dimension in which the KPI belongs to, along
        with its
        associated weights in the specific dimension for each aggregation level selected in the 1<sup>st</sup> step. The
        weights
        are utilized in equation (2) to calculate the results of the SII analysis. There is the option of equal weights
        (“ignore weight” tick box) regarding the specific dimension and aggregation level, which gives equal weights in
        all the KPIs belonging to the specific dimension and aggregation level.
      </p>

      <div class="image-container">
        <img src="@/images/manual/kpi-step-2.png" alt="psis index page">
        <div class="img-caption">Figure 6. KPI creation page – step 2: KPI dimension and associated weights.</div>
      </div>

      <p>
        In the 3<sup>rd</sup> step of the KPI creation (Figure 7) the user provides the sectors of the KPI (in contrast
        to the
        dimensions, one KPI can belong to more than one sectors) and their associated weights for each aggregation
        level, similar to step 2.
      </p>

      <div class="image-container">
        <img src="@/images/manual/kpi-step-3.png" alt="psis index page">
        <div class="img-caption">Figure 7. KPI creation page – step 3: KPI sectors and associated weights.</div>
      </div>

      <p>
        In the 4<sup>th</sup> and final step of the KPI creation the user provides the margins of the KPI, similar to the PSIs as
        presented in section “Creation of new PSI”, as shown in Figure 8. The given margins are applied in all
        aggregation levels to calculate the score of the KPI in each aggregation level. If the FU of the KPI is Likert
        scale then this step is ignored.
      </p>

      <div class="image-container">
        <img src="@/images/manual/kpi-step-4.png" alt="psis index page">
        <div class="img-caption">Figure 8. KPI creation page – step 4: margins.</div>
      </div>


    </div>
  </div>
</template>

<script>

export default {
  name: "NewKpi",
}
</script>

<style scoped>

</style>