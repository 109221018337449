<template>
  <div class="">
    <h2>{{ formSteps[currentFormStep] }}</h2>
    <hr v-if="currentFormStep > 0">
    <b-card v-if="currentFormStep > 0" :header="clearAndCapitalize(form.kpi.name)" class="text-center card-azure">
      <b-card-text>{{ selectedFunctionalUnit }}</b-card-text>
    </b-card>
    <hr>
    <b-form @submit.prevent="submitEvent">
      <FirstStep
          v-if="currentFormStep === 0"
          v-model="form"
          :kpi-types="kpiTypes"
          @name-changed="onNameChange"
      ></FirstStep>

      <SecondStep
        v-if="currentFormStep === 1"
        v-model="form"
        :accumulated-weights="accumulatedWeights"
        :ignored-weights-server="ignoredWeights"
      ></SecondStep>

      <ThirdStep
        v-if="currentFormStep === 2"
        v-model="form"
        :accumulated-weights="accumulatedWeights"
        :ignored-weights-server="ignoredWeights"
      ></ThirdStep>

      <FourthStep
        v-if="currentFormStep === 3"
        v-model="form"
      ></FourthStep>

      <b-list-group v-if="messages.length > 0" class="mb-2">
        <b-list-group-item variant="danger" v-for="(message, index) in messages" :key="`message${index}`">
          {{ message }}
        </b-list-group-item>
      </b-list-group>

      <b-row>
        <b-col>
          <b-button
              v-if="!(currentFormStep === 0)"
              type="button"
              variant="primary"
              @click.prevent="back"
              @click="$event.target.blur()"
          >Back</b-button>

          <b-button
              v-if="(currentFormStep < 3)"
              type="button"
              variant="primary"
              @click.prevent="proceed"
              @click="$event.target.blur()"
          >Next</b-button>

          <b-button
              v-if="currentFormStep === 3"
              type="submit"
              variant="success"
          >Submit</b-button>
        </b-col>
        <b-col>
          <b-button
              v-if="currentFormStep > 0"
              type="button"
              variant="warning"
              class="float-end"
              @click="$emit('save')"
          >Save and Exit
          </b-button>
          <inertia-link :href="this.$routes.project(this.projectId)">
            <b-button
                class="float-end"
                style="margin-right: 5px;"
            >Cancel</b-button>
          </inertia-link>

        </b-col>
      </b-row>
    </b-form>
    <br>

  </div>
</template>

<script>
import FirstStep from "./form_steps/FirstStep";
import SecondStep from "./form_steps/SecondStep";
import ThirdStep from "./form_steps/ThirdStep";
import FourthStep from "./form_steps/FourthStep";
import StepProgress from 'vue-step-progress';
import 'vue-step-progress/dist/main.css';

import StringManipulation from "@/Mixins/StringManipulation";

export default {
  components: {
    FirstStep,
    SecondStep,
    ThirdStep,
    FourthStep,
    'step-progress': StepProgress
  },
  mixins: [StringManipulation],
  props: {
    projectId: {
      type: Number,
      required: true
    },
    value: {
      type: Object,
      required: true
    },
    prototypes:{
      type: Object,
      required: false
    },
    kpiTypes: {
      type: Array,
      required: true
    },
    defaultKpiValues: {
      type: Object,
      required: false
    },
    accumulatedWeights: {
      type: Object,
      required: false,
      default: null
    },
    ignoredWeights: {
      type: Array,
      required: false,
      default: function () {
        return []
      }
    }
  },
  data() {
    return {
      currentFormStep: 0,
      cannotProceed: false,
      messages: [],
      temp: 1,
      formSteps: [
        'KPI selection, aggregation levels and values',
        'Dimension and weights',
        'Sectors and weights',
        'Margins'
      ]
    }
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    sectorsIsolated: function () {
      return this.form.kpi.sectors_attributes
    },
    selectedFunctionalUnit: function () {
      const obj = this.kpiTypes.find(type => type.value === this.form.kpi.name)
      if (obj !== undefined)
        return obj.functionalUnit
      return null
    },
    marginsValidationHelper: function () {
      return this.marginsValidationMethods()
    }
  },
  // to watch when likert scale - TODO: check if there is a better way
  watch: {
    sectorsIsolated: function (newVal, oldVal) {
      this.temp = 2
    }
  },
  methods: {
    submitEvent () {
      this.messages = []
      this.proceedFourthStep()
      if (this.messages.length === 0) this.$emit('submit')
    },
    proceed () {
      if (this.currentFormStep === 0) this.proceedFirstStep()
      else if (this.currentFormStep === 1) this.proceedSecondStep()
      else if (this.currentFormStep === 2) this.proceedThirdStep()

      if (this.messages.length === 0)
        this.currentFormStep += 1
    },
    back () {
      this.currentFormStep -= 1
    },
    // there might be a better way to do this
    proceedFirstStep () {
      this.messages = []
      const isLikert = this.form.kpi.likert === 'true'
      if (this.form.kpi.name == null || this.form.kpi.name === '') this.messages.push('Empty KPI name.')
      if (this.form.aggregationLevels.length === 0) this.messages.push('No aggregation levels selected.')
      let aggregationLevelValues = true
      let likertGreaterThanFive = false
      this.form.aggregationLevels.forEach(function callbackFn (level) {
        if (level.value === "") aggregationLevelValues = false
        if (level.value > 5 && isLikert) likertGreaterThanFive = true
      })
      if(!aggregationLevelValues) this.messages.push('Missing values from aggregation levels.')
      if (likertGreaterThanFive) this.messages.push('Liker is selected. Please provide values between 0-5.')
    },
    proceedSecondStep () {
      this.messages = []
      if (this.form.kpi.dimension_attributes.name === undefined || this.form.kpi.dimension_attributes.name == null)
        this.messages.push('No dimension selected')
      let aggregationLevelValues = true
      let weightsAboveUnit = false
      this.form.aggregationLevels.forEach(function callbackFn (level) {
        if (level.dimension_weight === "" || level.dimension_weight === undefined) aggregationLevelValues = false
        if (level.dimension_weight > 1) weightsAboveUnit = true
      })
      if(!aggregationLevelValues) this.messages.push('Missing weights from aggregation levels.')
      if (weightsAboveUnit) this.messages.push('Some weights are >=1 .')
    },
    proceedThirdStep () {
      this.messages = []
      const form = this.form
      const messages = this.messages
      let weightsAboveUnit = false
      // if (this.form.kpi.sectors_attributes.length === 0) this.messages.push('No sectors selected.')
      form.kpi.sectors_attributes.forEach(function callbackFn (sector) {
        form.aggregationLevels.forEach(function callbackFn (level, index) {
          const sectorKey = `sector_${sector.name}_weight`

          if ((level[sectorKey] === undefined || level[sectorKey] === ""))
            if (!messages.includes('No weights assigned to all sectors.'))
              messages.push('No weights assigned to all sectors.')
          if (level[sectorKey] > 1) weightsAboveUnit = true
        })
      })
      if (weightsAboveUnit) messages.push('Some weights are >=1 .')
    },
    proceedFourthStep () {
      // check margins the new way
      this.messages = []
      if (!this.isLikertScale()) {
        const marginsList = this.kpiMarginsList()
        for (let i = 0; i < marginsList.length; i++) {
          const currentMargins = marginsList[i]
          if (!currentMargins.level) continue;
          this.marginsValidationHelper.validateSetOfMargins(currentMargins)
        }
      }
    },
    selectedAggregationLevelsText () {
      return this.form.aggregationLevels.map(l => l.text).join(', ');
    },
    onNameChange ($event) {
      const name = $event
      const kpi = this.form.kpi
      if (this.defaultKpiValues && this.defaultKpiValues[name] !== undefined) {
        // clean up
        this.form.aggregationLevels = []
        const defaultValues = this.defaultKpiValues[name]
        this.form.aggregationLevels = defaultValues['aggregationLevels']
        kpi.dimension_attributes = defaultValues['kpi']['dimension_attributes']
        kpi.sectors_attributes = defaultValues['kpi']['sectors_attributes']
        kpi.likert = defaultValues['kpi']['likert']
        // margins
        const defaultMargins = defaultValues['kpi']['margins_attributes']
        const inputMargins = kpi.kpi_margins_attributes
        if (defaultMargins instanceof Array) {
          inputMargins.push({ level: 'common', m0: defaultMargins[0],
          m1: defaultMargins[1], m2: defaultMargins[2], m3: defaultMargins[3] })
        } else if (defaultMargins instanceof Object) {
          const keys = Object.keys(defaultMargins)
          for (let i = 0; i < keys.length; i++) {
            const m = defaultMargins[keys[i]]
            inputMargins.push({ level: keys[i], m0: m[0], m1: m[1],
                m2: m[2], m3: m[3]})
          }
        }
        // kpi.m0 = defaultValues['kpi']['m0']
        // kpi.m1 = defaultValues['kpi']['m1']
        // kpi.m2 = defaultValues['kpi']['m2']
        // kpi.m3 = defaultValues['kpi']['m3']
      } else {
        kpi.dimension_attributes = { name: null }
        kpi.sectors_attributes = []
        kpi.kpi_margins_attributes = []
        this.form.aggregationLevels = []
      }
    },
    marginsValidationMethods () {
      const self = this
      return {
        validateSetOfMargins: function (margins) {
          const marginsLevel = margins.level
          for (let j = 0; j < 4; j++) {
            const currentMargin = margins[`m${j}`]
            if (self.isEmptyString(currentMargin)) {
              self.messages.push(`Please provide all margins for ${self.clearAndCapitalize(marginsLevel)}.`)
              break;
            }
          }

          if (self.isMonotonic([margins.m0,
            margins.m1, margins.m2, margins.m3]) === 'error')
            self.messages.push(`Please provide ascending or descending margins for ${self.clearAndCapitalize(marginsLevel)}.`)
        }
      }
    },
    isLikertScale () {
      const likert = this.form.kpi.likert
      const likertDataType = typeof likert

      if (likertDataType === 'boolean')
        return likert
      else if (likertDataType === 'string')
        return (this.form.kpi.likert !== 'false' && this.form.kpi.likert !== null)
      else
        return false
    },
    kpiMarginsList () {
      return this.form.kpi.kpi_margins_attributes
    }
  }

}
</script>

<style lang="css" scoped>
  .agg-level {
    background: #fff;
    border-radius: 5px;
    color: #000;
    margin-bottom: 10px;
  }
</style>
