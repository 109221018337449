<template>
  <b-row class="form-step">
    <b-col>
      <b-form-group>
        <b-row v-if="form.kpi.likert === 'false' || !form.kpi.likert">
          <div style="margin-bottom: 15px;">
            <span class="text-muted">Provide the margins</span>
            <InfoIcon message="The values of the margins must be in ascending or descending order."></InfoIcon>
          </div>
          <b-col cols="12">
            <div style="background: white; padding: 10px; border-radius: 5px; margin: 10px; width: max-content;">
              <b-form-checkbox
                  id="common_margins"
                  v-model="commonMargins"
                  name="common_margins"
                  value="true"
                  unchecked-value="false"
                  @change="updateMarginsList"
              >
                <span style="margin-left: 10px; cursor: pointer">Use common margins for all aggregation levels.</span>
              </b-form-checkbox>
            </div>
            <div class="margins-level-container" v-if="commonMargins==='false'">
              <div class="margins-level-prompt">Please select an aggregation level:</div>
              <b-form-select
                  class="margins-level-select"
                  v-model="selectedLevel"
                  :options="optionsLevels"
              ></b-form-select>
            </div>

          </b-col>

          <MarginsInput
            v-for="(item, index) in form.kpi.kpi_margins_attributes"
            :key="`margins_${index}`"
            :value="item"
            :selected-level="selectedLevel"
          ></MarginsInput>

        </b-row>
        <b-row v-else>
          <b-col class="mb-2"><i>Likert scale was selected. There is no need to manually set margins.</i></b-col>
          <hr>
        </b-row>
      </b-form-group>

    </b-col>
  </b-row>
</template>

<script>
import MarginsFunctions from "@/Mixins/MarginsFunctions";
import MarginsDistributionPlot from "@/Shared/MarginsDistributionPlot";
import MarginsInput from "../extra_components/MarginsInput";

export default {
  name: "FourthStep",
  components: {
    MarginsDistributionPlot,
    MarginsInput
  },
  props: {
    value: {
      type: Object,
      required: true
    },
  },
  data () {
    return {
      selectedLevel: '',
      commonMargins: 'false'
    }

  },
  computed: {
    form: {
      get() {
        return this.value
      },
    },
    optionsLevels: function () {
      let arr = []
      const kpiMargins = this.form.kpi.kpi_margins_attributes
      for (let i = 0; i < kpiMargins.length; i++)
        if (kpiMargins[i].level)
          arr.push(this.clearAndCapitalize(kpiMargins[i].level))
      return arr
    }
  },
  methods: {
    updateMarginsList () {
      const margins = this.form.kpi.kpi_margins_attributes
      // keep ids that may exist (in edit mode)
      let ids = []
      for (let i = 0; i < margins.length; i++)
        if (margins[i].id !== undefined)
          ids.push(margins[i].id)

      if (this.commonMargins === 'true') {
        // remove everything from the list
        margins.splice(0, margins.length)

        // add the 'common' level
          margins.push({ level: 'common', m0: null, m1: null, m2: null, m3: null })
        this.selectedLevel = 'common'
      } else {
        // if the user unchecks common reveal add margins per aggregation
        margins.splice(0, margins.length)

        const levels = this.form.aggregationLevels
        for (let i = 0; i < levels.length; i++)
          margins.push( {level: levels[i].name, m0: null, m1: null, m2: null, m3: null } )

        this.selectedLevel = this.clearAndCapitalize(levels[0].name)
      }

      // add the _destroy keys for previously existing margins
      for (let i = 0; i < ids.length; i++)
        margins.push({ id: ids[i], _destroy: 1 })
    }
  },
  mounted () {
    const marginsAttrs = this.form.kpi.kpi_margins_attributes
    if (marginsAttrs.length > 0) {
      if (marginsAttrs.findIndex(e => e.level === 'common') > -1) {
        this.commonMargins = 'true'
      }
      this.selectedLevel = this.clearAndCapitalize(marginsAttrs[0].level)
    }

  }
}
</script>

<style scoped>

</style>