<template>
  <div class="home">
    <nav class="navbar fixed-top navbar-expand-lg navbar-dark bg-dark transparent">
      <div class="container-fluid">
        <inertia-link class="navbar-brand " :href="$routes.root()"><img class="imgHead" src="../../assets/images/logos/cperi.png" alt="cperi" width="60" height="60"></inertia-link>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <inertia-link class="nav-link active" :href="$routes.root()">Home</inertia-link>
            </li>
            <li class="nav-item">
              <inertia-link class="nav-link" :href="$routes.projects()">Projects</inertia-link>
            </li>
            <li class="nav-item">
              <inertia-link class="nav-link" :href="$routes.user_manual()">Manual</inertia-link>
            </li>
          </ul>
          <inertia-link
                  v-if="$page.props.auth.user"
                  :href="$routes.destroy_user_session()"
                  method="delete"
                  as="button"
                  class="btn btn-primary"
              >Log out</inertia-link>
          <inertia-link
                  v-else
                  :href="$routes.new_user_session()"
                  as="button"
                  class="btn btn-primary"
              >Log in</inertia-link>
        </div>
      </div>
    </nav>  
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-6 leftBlock">
          <h1>USE</h1>
          <p><strong>U</strong>niform <strong>S</strong>mart city <strong>E</strong>valuation</p>
          <i class="text">USE is an emerging framework proposed to cover the needs of a tool capable to provide 
            a method of identifying the range of success of a Smart Cities and Communities (SCC) projects. 
            Although the mechanisms of the proposed framework reach further more from the project evaluation,
            as it can also compare, benchmark and design future projects.
          </i>
          <div style="background: white; border-radius: 5px;">
            <img class="euProj" src="../../assets/images/logos/rinno.jpg" alt="" srcset="" width="80" height="80">
            <img class="euProj" src="../../assets/images/logos/pocityf.png" alt="" width="100" height="70">
            <img class="euProj" src="../../assets/images/logos/ianos_logo.png" alt="" srcset="" width="80" height="80">
            <img class="euProj" src="../../assets/images/logos/auroral.png" alt="" width="100" height="100">
            <img class="euProj" src="../../assets/images/logos/response.png" alt="" width="120px">
          </div>
          <div>
            <img src="../../assets/images/logos/cperi-certhnew.png" alt="cperi-certh" width="180" height="100" >
          </div>
        </div>
        <div class="col-lg-6 rightBlock">
          <div>
            <img src="../../assets/images/logos/use.png" alt="cperi-use" width="600" height="500">
          </div>
        </div>
      </div>
    </div> 
    <nav class="navbar fixed-bottom navbar-dark bg-dark footer">
      <p>Copyright © Certh/CPERI</p>
      <img class="imgFooter" src="../../assets/images/logos/transparent/logo-eu-new2.png" alt="" srcset="">
    </nav>
  </div>  
</template>

<script>  
  export default {
    
  }
</script>

<style scoped>
.imgFooter{
  width: 200px;
  height: 60px;
}
.home{
  color:white;
  background-image: url("../../assets/images/logos/beautiful-milky-way-night-sky.jpg");
  background-size: cover;
  position:fixed;
  width:100%;
  height:100%;
  overflow: auto;
  top:0px;
  left:0px;
  z-index:1000;
  font-size: 20px;
}
.leftBlock{
  display: flex;
  align-items: center;
  flex-direction: column;
  position:relative;
}
.rightBlock{
  display: flex;
  align-items: center;
  flex-direction: column;
}
.row{
  padding-top: 7%;
}
.leftBlock p{ font-size: 1.37rem;}
.text{
  display: block;
  width: 80%;
  text-overflow: ellipsis;
  line-height: 200%;
  text-align: justify;
  align-self: center;
  font-size: 1.37rem;
  
}
.euProj{
  margin: 10px;
}
.navbar{
  opacity: 0.9;
}
.footer{
  padding-left: 10px;
  padding-right: 10px;
}

@media (max-width: 1300px){
  .navbar{
    zoom:70%;
  }
  .row{
    zoom:80%;
  }
  .imgHead{
    width: 90px;
    height: 90px;
  }
  .leftBlock{
    padding-top: 70px !important;
    padding-bottom: 100px !important;
  }  
  .rightBlock{
    padding-bottom: 100px !important ;
  }
}
</style>
