<template>
  <div>
    <b-container>
      <b-row>
        <b-col cols="=12">
          <h2>{{ psi.id }}. {{ clearAndCapitalize(psi.name) }}</h2>
        </b-col>
        <hr>
        <b-col cols="12">
          <b-table
              striped
              hover
              :fields="tableFields"
              :items="[psi]"
              class="deep-blue-header-table"
          >
          </b-table>
          <hr>
        </b-col>
        <b-col>
          <div>
            <b-table striped hover :items="scale" class="indigo-header-table">
              <template #thead-top="data">
                <b-tr style="">
                  <b-th v-if="psi.relative_margins" colspan="2">Margins (relative)</b-th>
                  <b-th v-else colspan="2">Margins (absolute)</b-th>
                </b-tr>
              </template>
            </b-table>
          </div>

        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <inertia-link :href="$routes.project(projectId)">Back to project</inertia-link> |
          <inertia-link :href="$routes.edit_project_psi(projectId, psi.id)">Edit</inertia-link>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import MainLayout from '@/Layouts/Main'
import StringManipulation from "@/Mixins/StringManipulation";
import MarginsFunctions from "../../Mixins/MarginsFunctions";
export default {
  name: "Show",
  mixins: [StringManipulation, MarginsFunctions],
  layout: MainLayout,
  props: {
    projectId: {
      type: Number,
      required: true
    },
    psi: {
      type: Object,
      required: true
    }
  },
  computed: {
    scale: function () {
      return this.showPageMargins([this.psi['m0'], this.psi['m1'],
          this.psi['m2'], this.psi['m3']])
    }
  },
  data () {
    return {
      tableFields: ['value', 'call_impact', 'target_value']
    }
  }
}
</script>

<style scoped>

</style>