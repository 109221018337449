<script>
import { Radar, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  name: "Radar",
  extends: Radar,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    aspectRatio: {
      type: Object,
      required: true,
      default: null
    }
  },
  mounted () {
    if (this.aspectRatio) {
      this.$refs.canvas.width = this.aspectRatio.width
      this.$refs.canvas.height = this.aspectRatio.height
    }
    this.renderChart(this.chartData, this.options)
  }
}
</script>