import { render, staticRenderFns } from "./PsisAnalysis.vue?vue&type=template&id=89974de8&scoped=true&"
import script from "./PsisAnalysis.vue?vue&type=script&lang=js&"
export * from "./PsisAnalysis.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89974de8",
  null
  
)

export default component.exports