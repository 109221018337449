import { render, staticRenderFns } from "./NewKpi.vue?vue&type=template&id=446a6c18&scoped=true&"
import script from "./NewKpi.vue?vue&type=script&lang=js&"
export * from "./NewKpi.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "446a6c18",
  null
  
)

export default component.exports