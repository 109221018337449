export default {
    methods: {
        getMarginLabel(index) {
            if (index === 0)
                return 'Failed'
            else if (index === 1)
                return 'Underachieved'
            else if (index === 2)
                return 'Achieved'
            else if (index === 3)
                return 'Overachieved'
            else
                return 'Excelled'
        },
        getMarginColor (index) {
            if (index === 0)
                return 'background: rgb(255 72 72);'
            else if (index === 1)
                return 'background: #ffa700;'
            else if (index === 2)
                return 'background: #fff400;'
            else if (index === 3)
                return 'background: rgb(125 195 0);'
            else
                return 'background: #2cba00;'
        },
        showPageMargins (margins) {
            let arr = []
            const colors = ['failed', 'underachieved', 'achieved', 'overachieved']
            for (let i = 0; i < 4; i++) {
                let obj = {
                    scale: this.getMarginLabel(i),
                    value: margins[i],
                    _rowVariant: colors[i]
                }
                arr.push(obj)
            }
            const monotonous = this.isMonotonic(margins)
            let label = ''
            if (monotonous === 'asc') label = `>= ${margins[3]}`
            else label = `<= ${margins[3]}`

            let obj = {
                scale: 'Excelled',
                value: label,
                _rowVariant: 'excelled'
            }
            arr.push(obj)
            return arr
        },
        normalizeMargins (values) {
            let arr = []
            const min = Math.min(...values)
            const max = Math.max(...values)

            for (let i = 0; i < values.length; i++) {
                const v = values[i]
                arr.push((v - min) / (max - min))
            }
            return arr
        }
    }
}