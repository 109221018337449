<!-- 
 This component is used to display the PSIs (Project Success Indicators) associated with a specific project.
 It is used in the `projects_controller.rb` and its route is psis_overview_project(:project_id)
 
 Props:
 
 - `project` (Object): Contains the details of the project being displayed.
    - Default: null
    - Required: false
    - Usage: This prop is used to display the project name in the table header and provide a back link to the project's page.
 
 - `psis_list` (Array): An array containing the list of PSIs (Project Success Indicators) related to the project.
    - Default: null
    - Required: false
    - Usage: This prop is used to generate the rows in the PSI table. Each row represents a PSI and its associated details such as ID, name, value, etc.
  
 The table supports scrolling for large datasets and includes sticky headers for easy navigation.
  -->
<template>
  <b-container>
    <div>
      <div class="table-wrapper">
        <table border="1" cellspacing="0" cellpadding="5">
          <thead>
            <tr>
              <th colspan="6">{{ project.name }} - PSIs</th>
            </tr>
            <tr>
              <th v-for="(column, index) in columns" :key="index">
                {{ formatName(column) }}
              </th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, rowIndex) in psis_list" :key="rowIndex">
              <td
                style=""
                v-for="(column, colIndex) in columns"
                :key="colIndex"
              >
                <span v-if="typeof row[column] === 'string'">
                  {{ formatName(row[column]) }}
                </span>
                <span v-else>
                  {{ row[column] }}
                </span>
              </td>
              <td>
                <inertia-link
                  :href="$routes.edit_project_psi(project.id, row['id'])"
                >
                  Edit PSI
                </inertia-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <UpdateValuesModal :project-id="project.id" method="psi" :errors="errors"></UpdateValuesModal>

    <b-row>
        <b-col>
          <inertia-link :href="$routes.project(project.id)" class="mb-2">
            Back to project
          </inertia-link>
        </b-col>
      </b-row>
  </b-container>
</template>

<script>
import MainLayout from '@/Layouts/Main'
import UpdateValuesModal from "./subcomponents/UpdateValuesModal.vue";

export default {
  components: {UpdateValuesModal},
  layout: MainLayout,
  data() {
    return {
      // Define the columns dynamically
      columns: ["id", "name", "value", "target_value", "call_impact"],
    };
  },
  props: {
    project: {
      type: Object,
      default: null,
      required: false,
    },
    psis_list: {
      type: Array,
      default: null,
      required: false,
    },
    errors: {
      type: Array,
      default: [],
      required: false
    }
  },
  created() {},
  computed: {},
  methods: {
    formatName(name) {
      return name
        .split("_") // Split the string by underscores
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
        .join(" "); // Join the words with spaces
    },
  },
};
</script>

<style scoped>
.table-wrapper {
  max-height: 800px; /* Set a fixed or max height for the table */
  overflow-y: auto; /* Enable vertical scrolling when content exceeds the height */
  overflow-x: auto; /* Enable horizontal scrolling for wide tables */
  border: 1px solid #ddd; /* Optional: Add a border around the scrollable area */
  display: block; /* Ensure block layout to handle scrolling */
}

thead th {
  position: sticky; /* Make the table headers stick to the top */
  top: 0; /* Position the sticky header at the top */
  background-color: #f8f9fa; /* Ensure the background color is set so the header stands out */
  z-index: 1; /* Ensure the header is above the table body */
}

table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
}

th,
td {
  padding: 12px 15px;
  text-align: left;
  border: 1px solid #ddd; /* Light border */
}

th {
  background-color: #f8f9fa; /* Light gray background for headers */
  font-weight: bold;
  text-align: center;
}

td {
  text-align: center;
  vertical-align: middle;
}

tbody tr:nth-child(even) {
  background-color: #f9f9f9; /* Alternating row color */
}

tbody tr:hover {
  background-color: #f1f1f1; /* Hover effect */
}

td:last-child {
  white-space: nowrap; /* Prevent the Actions column from wrapping text */
}

.table-action-links {
  color: #007bff;
  margin-right: 10px;
}

.table-action-links:hover {
  color: #0056b3;
}

button {
  background-color: #6c757d; /* Add button background */
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #5a6268; /* Darker background on hover */
}
</style>
