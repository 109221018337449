<template>
  <div>
    <h2>PPI Analysis Results</h2>
    <div class="manual-text-container">

      <p>
        After the PSIs of the simulated project/scenario have been inserted these are shown in the project show page as
        presented in Figure 3, along with some relevant details of each PSI.
      </p>


      <div class="image-container">
        <img src="@/images/manual/project-show-psi-only.png" alt="psis index page">
        <div class="img-caption">Figure 3. PSIs of the simulated scenario presented in the project show page.</div>
      </div>

      <p>
        By clicking in the “PPI result” button the tool calculates the score of each PSI as well as the final PPI score
        based on the harmonic mean of the PSI scores utilizing the equation below:
      </p>

      <div class="equation-container">
        <img src="@/images/manual/equation-1.png" alt="equation for the psis score"
             style="display: block; margin: auto">
        <div class="">where x1, x2, . . . , xn, are the PSI scores and n is the number of PSIs.</div>
      </div>
      <br>
      <p>
        The average score of each call impact number is also calculated based on the arithmetic mean of the scores of
        the PSIs that belong to the specific call impact number. The results take values ranging from 1 (very bad) to 5
        (excelled). These results along with the score of each PSI are presented using bar charts in the software, as
        shown Figure 4 below. The red line in the 2nd figure is the final PPI score.
      </p>

      <div class="image-container">
        <img src="@/images/manual/ppi-charts-1.png" alt="ppis sample chart 1">
        <img src="@/images/manual/ppi-charts-2.png" alt="ppis sample chart 2">
        <div class="img-caption">Figure 4. Example results of PPI analysis.</div>
      </div>

    </div>
  </div>
</template>

<script>

export default {
  name: "PpiResults",
  mounted() {
  }
}
</script>

<style scoped>

</style>