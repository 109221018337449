<template>
  <div>
    <h2>PPI Analysis</h2>
    <p class="manual-text-container">The first analysis that is performed is called Project Performance Index (PPI) analysis
      and is based on the Project Success Indicators (PSIs), which are metrics that are used to
      assess the successful (or not) implementation of each project’s interventions and their
      impact against pre-defined targets relevant to this specific project. </p>
  </div>
</template>

<script>
export default {
name: "PpiAnalysis"
}
</script>

<style scoped>

</style>