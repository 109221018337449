<template>
  <div>
    <h1 style="text-align: center; margin-bottom: 10px;">Edit KPI</h1>
    <KpiForm
        v-model="form"
        :project-id="projectId"
        :kpi-types="kpiTypes"
        :accumulated-weights="accumulatedWeights"
        :ignored-weights="ignoredWeights"
        @submit="submit"
        @save="saveAndExit"
    ></KpiForm>
  </div>
</template>

<script>
import MainLayout from '@/Layouts/Main'
import KpiForm from './Form'
export default {
  name: "Edit",
  layout: MainLayout,
  components: {
    KpiForm
  },
  props: {
    projectId: {
      type: Number,
      required: true
    },
    kpiId: {
      type: Number,
      required: true
    },
    kpi: {
      type: Object,
      required: true
    },
    kpiTypes: {
      type: Array,
      required: true
    },
    aggregationLevels: {
      type: Array,
      required: true
    },
    accumulatedWeights: {
      type: Object,
      required: false,
      default: null
    },
    ignoredWeights: {
      type: Array,
      required: false,
      default: function () {
        return []
      }
    }
  },
  data () {
    return {
      form: {
        kpi: this.kpi,
        aggregationLevels: this.aggregationLevels
      },
      initialSectors: [],
      initialAggregationLevels: []
    }
  },
  methods: {
    submit() {
      this.createFormObject(true)
      this.$inertia.put(this.$routes.project_kpi(this.projectId, this.kpiId), this.form)
    },
    saveAndExit() {
      this.createFormObject(false)
      this.$inertia.put(this.$routes.project_kpi(this.projectId, this.kpiId), this.form)
    },
    createFormObject (complete=true) {
      this.form.kpi.project_id = this.projectId
      const sectors = this.form.kpi.sectors_attributes
      const levels = this.form.aggregationLevels
      const initialSectors = this.initialSectors
      sectors.forEach(function callbackFn(sector, index) {
        const dbIds = {}
        let destroyIds = {}
        // check if sector has aggregation levels that are being edited
        if (sector.aggregation_level_results_attributes) {
          sector.aggregation_level_results_attributes.forEach(function callbackFn(attr, index) {
            dbIds[attr.name] = attr.id
          })
        }
        // check if a new aggregation level has been added
        sector.aggregation_level_results_attributes = []
        const sectorKey = `sector_${sector.name}_weight`
        levels.forEach(function callbackFn(level, index) {
          const aggObj = {
            name: level.name,
            value: level.value,
            weight: level[sectorKey],
            sector_id: sector.id,
            dimension_id: null,
            id: dbIds[level.name]
          }
          sector.aggregation_level_results_attributes.push(aggObj)
        })
      })

      // add notation for sectors that are destroyed
      initialSectors.forEach(function callbackFn(sector, index) {
        if (!sectors.find(s => s.name === sector.name)) {
          sectors.push({ _destroy: true, id: sector.id, name: sector.name })
        }
      })

      // new aggregation level array
      const initialLevels = this.initialAggregationLevels
      this.form.kpi.aggregation_levels_attributes = []
      const levelsAttrs = this.form.kpi.aggregation_levels_attributes
      levels.forEach(function callbackFn(level, index) {
        const existingObj = initialLevels.find(l => l.name === level.name)
        const id = existingObj ? existingObj.id : null
        const aggObj = {
          name: level.name,
          value: level.value,
          id: id
        }
        levelsAttrs.push(aggObj)
      })

      // add notation for aggregation levels to be destroyed
      initialLevels.forEach(function callbackFn(level, index) {
        if (!levels.find(s => s.name === level.name)) {
          levelsAttrs.push({ _destroy: true, id: level.id, name: level.name })
        }
      })

      const dimension = this.form.kpi.dimension_attributes
      const dbIds = {}
      dimension.aggregation_level_results_attributes.forEach(function callbackFn(attr, index) {
        dbIds[attr.name] = attr.id
      })

      dimension.aggregation_level_results_attributes = []
      levels.forEach(function callbackFn(level, index) {
        const aggObj = {
          name: level.name,
          value: level.value,
          weight: level.dimension_weight,
          sector_id: null,
          dimension_id: dimension.id,
          id: dbIds[level.name]
        }
        dimension.aggregation_level_results_attributes.push(aggObj)
      })

      this.form.kpi.state = complete
    }
  },
  mounted () {
    const sectors = this.form.kpi.sectors_attributes
    for (let i = 0; i < sectors.length; i++) {
      const sector = sectors[i]
      this.initialSectors.push({ name: sector.name, id: sector.id })
    }

    const levels = this.form.aggregationLevels
    for (let i = 0; i < levels.length; i++) {
      const level = levels[i]
      this.initialAggregationLevels.push({ name: level.name, id: level.id })
    }
  }
}
</script>

<style scoped>

</style>