<template>
  <div>
    <h2>SII and SPI Analyses Results</h2>
    <div class="manual-text-container">
      <p>
        After the KPIs of the simulated project/scenario have been inserted these are shown in the project show page as
        presented in Figure 9, along with some relevant details of each KPI.
      </p>

      <div class="image-container">
        <img src="@/images/manual/project-show-only-kpis.png" alt="list of project kpis">
        <div class="img-caption">Figure 9. KPIs of the simulated scenario presented in the project show page.</div>
      </div>

      <p>
        By clicking the “SII SPI result” button the tool calculates the score of each KPI (Figure 10), as well as the
        score of each dimension and sector for each aggregation level and eventually the final SII and SPI scores of
        each aggregation level, as explained below. All scores take values ranging from 1 (very bad) to 5 (excelled).
      </p>

      <div class="image-container">
        <img src="@/images/manual/sii-spi-charts-3.png" alt="spi and sii chart 1">
        <div class="img-caption">Figure 10. Example KPI scores of a demo project for the city aggregation level.</div>
      </div>

      <p>
        The score of each dimension and sector for each aggregation level is calculated as a weighted average of the
        individual scores of the KPIs belonging to the specific dimension or sector for each aggregation level using the
        following equation (2):
      </p>

      <div class="equation-container">
        <img src="@/images/manual/equation-2.png" alt="equation for the kpis score"
             style="display: block; margin: auto">
      </div>

      <p>
        where n is the number of KPIs in the specific dimension or sector for the specific aggregation level, wi is the
        weight of the ith KPI in the dimension or sector for the specific aggregation level and xi is the score of the
        ith KPI for the specific aggregation level.
        <br><br>
        Example results for one dimension (energy) and one sector (quality of life and prosperity) with 4 aggregation
        levels (building, PEB, PED and city) are presented in Figure 11 using bar charts. By clicking in the top left
        dropdown list the user can select different dimensions and sectors to show their results. The tick boxes
        “include Final SII scores” and “include Final SPI scores” give the option to the user to show in the same graph
        also the final SII and SPI scores of Figure 13 in thinner bars.
      </p>

      <div class="image-container">
        <img src="@/images/manual/sii-spi-charts-2.png" alt="spi and sii chart 2">
        <div class="img-caption">Figure 11. Example bar charts showing the scores of the energy dimension (left) and the
          quality of life and prosperity sector (right) in four aggregation levels.
        </div>
      </div>

      <p>
        In addition, Figure 12 presents the average score of each dimension and each sector for one aggregation level
        (building) using spider charts. The results of the other aggregation levels can be shown using the top left
        dropdown list.
      </p>

      <div class="image-container">
        <img src="@/images/manual/sii-spi-charts-1.png" alt="spi and sii chart 3">
        <div class="img-caption">Figure 12. Example scores in spider charts of the building aggregation level.</div>
      </div>

      <p>
        Finally, the final SII and SPI scores are calculated in each aggregation level as an arithmetic mean of the
        individual scores of the dimensions and sectors, respectively, that exist in the specific level. Example results
        are presented in Figure 13, which shows the final SII and SPI scores in each aggregation level of a demo
        project.
      </p>

      <div class="image-container">
        <img src="@/images/manual/sii-spi-charts-0.png" alt="spi and sii chart 4">
        <div class="img-caption">Figure 13. Example final SII and SPI scores in each aggregation level of a demo project presented in bar charts.</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SiiSpiResults"
}
</script>

<style scoped>

</style>