<template>
  <div>
    <b-row>
      <b-col cols="12">
        <ChartTitle :title= title></ChartTitle>
      </b-col>

      <b-col cols="12">
        <RadarChart
            :v-if="chartData"
            :chartData="chartDataCopy"
            :options="options"
            :aspect-ratio="{width: 2, height: 1}"
            class="bar-chart-plot-full-width"
        ></RadarChart>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import RadarChart from "../base/RadarChart";
import ChartTitle from "../components/ChartTitle";
export default {
  name: "SimpleRadarChart",
  components: {
    RadarChart,
    ChartTitle
  },
  props: {
    title: {
      type: String,
      default: null,
      required: false
    },
    chartData: {
      type: Object,
      required: true
    },
  },
  computed: {
    chartDataCopy () {
      return this.deepCopy(this.chartData)
    },
    options () {
      return {
        legend: {
          labels: {
            fontSize: 20
          }
        },
        scale: {
          gridLines: {
            color: 'orange'
          },
          angleLines: {
            color: 'orange'
          },
          ticks: {
            fontColor: 'black',
            backdropColor: 'rgba(255, 255, 255, 0.01)',
            beginAtZero: true,
            max: 5,
            fontSize: 16
          },
          pointLabels: {
            fontSize: 16
          }
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
