<template>
  <div>
    <b-table-simple bordered hover small caption-top responsive>
      <caption>Details for dimension and sectors per aggregation level</caption>
      <colgroup><col><col></colgroup>
      <colgroup><col><col><col></colgroup>
      <b-thead style="background: #6679a5; color: #fff">
        <b-tr>
          <b-th colspan="3">Categories</b-th>
          <b-th colspan="2">Values</b-th>
        </b-tr>
        <b-tr>
          <b-th>Name</b-th>
          <b-th>Dimension/Sector</b-th>
          <b-th>Aggregation Level</b-th>
          <b-th>Weight</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(c, index) in combinations" :key="`${c.level}_${c.name}`" :variant="getColor(c.type)">
          <b-th :rowspan="rowsPerLevel" v-if="index % rowsPerLevel === 0">{{ clearAndCapitalize(c.name) }}</b-th>
          <b-td :rowspan="rowsPerLevel" v-if="index % rowsPerLevel === 0">{{ capitalize(c.type) }}</b-td>
          <b-td>{{ clearAndCapitalize(c.level) }}</b-td>
          <b-td>{{ c.weight }}</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
import StringManipulation from '@/Mixins/StringManipulation'
export default {
  name: "NestedHeaderTable",
  mixins: [StringManipulation],
  props: {
    levels: {
      type: Array,
      required: true
    },
    sectors: {
      type: Array,
      required: true
    },
    dimension: {
      type: Object,
      required: true
    }
  },
  computed: {
    combinations: function () {
      const levels = this.levels
      const sectors = this.sectors
      const dimension = this.dimension
      let arr = []

      // add sector combinations
      for (let i = 0; i < sectors.length; i++) {
        const sector = sectors[i]
        for (let l = 0; l < sector.agg.length; l++){
          const level = sector.agg[l]
          if (levels.includes(level.name)) {
            arr.push({
              level: level.name,
              type: 'sector',
              name: sector.name,
              value: level.value,
              weight: level.weight == null ? '-' : level.weight
            })
          }
        }
      }
      arr = arr.sort((a, b) => (a.name > b.name) ? 1 : -1)
      // add dimension combinations
      for (let l = 0; l < dimension.agg.length; l++) {
        const level = dimension.agg[l]
        if (levels.includes(level.name)) {
          arr.unshift({
            level: level.name,
            type: 'dimension',
            name: dimension.name,
            value: level.value,
            weight: level.weight
          })
        }
      }

      return arr
    },
    rowsPerLevel: function () {
      return this.levels.length
      // return this.sectors.length + 1 // sectors length + 1 dimension
    }
  },
  methods: {
    getColor(type) {
      if (type === 'dimension') return 'primary'
      else return 'success'
    }
  }
}
</script>

<style scoped>

</style>