<template>
  <b-row class="form-step">
    <b-col>
      <div>
        <label class="typo__label">Select Dimension <InfoIcon message="A single dimension must be selected."></InfoIcon></label>
        <multiselect
            v-model="form.kpi.dimension_attributes.name"
            deselect-label="Can't remove this value"
            placeholder="Select one"
            :options="optionsDimension"
            :searchable="true"
            :allow-empty="false"
            :custom-label="dimensionLabel"
            @select="onDimensionChange"
        >
        </multiselect>
      </div>
      <hr>
      <b-form-group>
        <b-row v-if="form.aggregationLevels.length > 0" class="mb-3">
          <b-col>
            Enter weight for each aggregation level <InfoIcon message="Enter values in range [0,1]. Non-normalized weights will be supported in the future."></InfoIcon>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="3" v-for="(level, index) in form.aggregationLevels" :key="level.name">
            <div class="agg-level">
              <span>
                <div><strong>{{ capitalize(level.name) }}</strong></div>
                <b-form-input
                    :id="`${level.name}_dimension_weight`"
                    v-model="form.aggregationLevels[index].dimension_weight"
                    type="number"
                    :placeholder="getPlaceholder(index)"
                    :readonly="form.aggregationLevels[index].dimension_weight === null"
                    required
                >
              </b-form-input>
                <div
                    v-if="accumulatedWeights[dimensionName] && parseFloat(accumulatedWeights[dimensionName][level.name]) > 0"
                    style="float: right"
                >
                  <i class="text-orange">
                    (accumulated weight: {{ accumulatedWeights[dimensionName][level.name] }})
                  </i>
                </div>
                <i class="text-orange" v-else>(No accumulated weight)</i>
              </span>

            </div>
          </b-col>
        </b-row>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import Multiselect from "vue-multiselect";
import StringManipulation from "@/Mixins/StringManipulation";

export default {
  name: "SecondStep",
  components: {
    Multiselect
  },
  mixins: [StringManipulation],
  props: {
    value: {
      type: Object,
      required: true
    },
    accumulatedWeights: {
      type: Object,
      required: false,
      default: null
    },
    ignoredWeightsServer: {
      type: Array,
      required: false,
      default: function () {
        return []
      }
    }
  },
  computed: {
    form: {
      get() {
        return this.value
      },
    },
    dimensionName () {
      return this.form.kpi.dimension_attributes.name
    },
  },
  data() {
    return {
      selectedDimension: null,
      // this will be passed from the server
      optionsDimension: ['economic', 'energy', 'environmental', 'governance', 'ict', 'mobility', 'propagation', 'social'],
      ignoreWeightsForLevel: []
    }
  },
  methods: {
    dimensionLabel(v) {
      return v.charAt(0).toUpperCase() + v.slice(1)
    },
    callOnIgnoreWeight (index, value) {
      if (value) {

        this.form.aggregationLevels[index].dimension_weight = null

      }

    },
    onDimensionChange ($event) {
      // check if it in the ignored weights list to set it to null
      const selected = $event
      const items = this.ignoredWeightsServer.filter(e => e.dimension === selected)
      let ignored = []
      let notIgnored = []
      // find dimension in aggregation levels
      const levels = this.form.aggregationLevels
      if (items.length > 0) {
        for (let i = 0; i < items.length; i++) {
          const record = levels.filter(e => e.name === items[i].level)[0]
          ignored.push(record.name)
          record['dimension_weight'] = null
        }
      }
      notIgnored = levels.filter(e => !(ignored.includes(e.name)))
      for (let i = 0; i < notIgnored.length; i++) {
        const record = levels.filter(e => e.name === notIgnored[i].name)[0]
        record['dimension_weight'] = ''
      }
    },
    getPlaceholder (index) {
      if (this.form.aggregationLevels[index].dimension_weight === null)
        return 'Weight is ignored'
      return 'Enter weight'
    }
  },
  mounted() {
    // for (let i = 0; i < this.form.aggregationLevels.length; i++) {
    //   this.ignoreWeightsForLevel.push(false)
    //   this.form.aggregationLevels[i].dimension_weight = ""
    // }
    // ---- new from server ignored weights
    for (let i = 0; i < this.ignoredWeightsServer.length; i++) {
      const item = this.ignoredWeightsServer[i]
      const alItem = this.form.aggregationLevels.filter(e => e.name === item.level)[0]
      if (alItem && this.form.kpi.dimension_attributes.name.toLowerCase() === item.dimension)
        alItem[`dimension_weight`] = null
    }

    // find if there are null but something has changed in ignore weights
    // e.g. saved a kpi when mobility-building was being ignored
    // but the user changes this preference so it has to be '' from null
    const aggLevels = this.form.aggregationLevels
    const dimension = this.form.kpi.dimension_attributes['name']
    for (let i = 0; i < aggLevels.length; i++) {
      if (aggLevels[i]['dimension_weight'] === null) {
        const name = aggLevels[i]['name']
        const item = this.ignoredWeightsServer.filter(e => e.dimension === dimension && e.level === name)[0]
        if (!item) aggLevels[i]['dimension_weight'] = ''
      }
    }
  }
}
</script>

<style scoped>

</style>