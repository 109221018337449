<template>
  <div class="form-step">
    <b-row>
      <b-col cols="6">
        <b-form-group label="Name">
          <multiselect
              v-model="selectedKpi"
              :options="kpiTypes"
              placeholder="Select one"
              label="text"
              track-by="value"
              @select="onChangeMutli"
          ></multiselect>



          <b-form-invalid-feedback :state="validation">
            Please select a name from the list.
          </b-form-invalid-feedback>
          <b-form-valid-feedback :state="validation">
            Name selected.
          </b-form-valid-feedback>
        </b-form-group>
        <span v-if="selectedFunctionalUnit" style="font-size: 14px;"><strong >Functional Unit: </strong>{{ selectedFunctionalUnit }}</span>
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col cols="12">
        <div class="multiselect-dropdown">
          <label>Aggregation Levels <InfoIcon message="One or more aggregation levels must be selected."></InfoIcon></label>
          <multiselect
              v-model="selectedAggregations"
              :options="optionsAggregation"
              :multiple="true"
              :close-on-select="true"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Pick aggregation levels"
              label="text"
              track-by="value"
              @select="addAggregationLevel"
              @remove="removeAggregationLevel"
          ></multiselect>
        </div>
        <hr>
      </b-col>

      <b-col>
        <b-form-group>
          <b-row v-if="form.kpi.likert === 'true'">
            <b-col>
              <b-form-checkbox
                  id="kpi_likert"
                  v-model="form.kpi.likert"
                  name="kpi_likert"
                  value="true"
                  unchecked-value="false"
                  disabled
              >
                <span>Likert scale will be used</span>
              </b-form-checkbox>
              <hr>
            </b-col>
          </b-row>
          <b-row v-if="form.aggregationLevels.length > 0" class="mb-3">
            <b-col>
              Enter value for each aggregation level <InfoIcon message="Enter a real value for each aggregation level. If the functional unit is Likert Scale then the value should be in range [1, 5]."></InfoIcon>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="3" v-for="(level, index) in form.aggregationLevels" :key="level.name">
                <span><strong>{{capitalize(level.name)}}</strong></span>
                <b-form-input
                    :id="`${level.name}_value`"
                    v-model="form.aggregationLevels[index].value"
                    type="number"
                    placeholder="Enter value"
                    :min="minValue"
                    :max="maxValue"
                    @keyup="onValueChange($event, index)"
                    required
                >
                </b-form-input>
            </b-col>
          </b-row>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import StringManipulation from "@/Mixins/StringManipulation";

export default {
  name: "FirstStep",
  components: {
    Multiselect
  },
  mixins: [StringManipulation],
  props: {
    value: {
      type: Object,
      required: true
    },
    kpiTypes: {
      type: Array,
      required: true
    },
  },
  computed: {
    form: {
      get() {
        return this.value
      },
    },
    validation() {
      return this.form.kpi.name !== null
    },
    selectedAggregations: {
      get () {
        let arr = []
        for (let i = 0; i < this.form.aggregationLevels.length; i++)
          arr.push({ value: this.form.aggregationLevels[i].name, text: this.form.aggregationLevels[i].text })

        return arr
      },
      set(val) {
      }
    },
    selectedFunctionalUnit: function () {
      const obj = this.kpiTypes.find(type => type.value === this.kpiName.value)
      if (obj !== undefined)
        return obj.functionalUnit
      return ''
    },
    minValue () {
      return 0
    },
    maxValue () {
      if (this.form.kpi.likert === 'true') return 5
      else return 1000
    },
    kpiName () {
      if(this.form.kpi.name) return this.kpiTypes.filter(e => e.value === this.form.kpi.name)[0]
      else return ''
    },
    selectedKpi: {

      get() {
        if(this.form.kpi.name) return this.kpiTypes.filter(e => e.value === this.form.kpi.name)[0]
        else return ''
      },
      set(name) {
        return name
      }

    }
  },
  data() {
    return {
      selectedAggregation: null,
      optionsAggregation: [
        { value: 'building', text: 'Building' },
        { value: 'peb', text: 'PEB' },
        { value: 'ped', text: 'PED' },
        { value: 'city', text: 'City' },
        { value: 'island', text: 'Island' },
      ],
      alreadySelectedAggregation: false,
      selectedKpiType: null,
      updatedCannotProceed: this.cannotProceed,
    }
  },
  methods: {
    // TODO: The two methods below do two things - separete them
    addAggregationLevel (level) {
      const formItems = this.form.aggregationLevels
      // adding dimension weight from this step, because I had some problems in
      // the second step. However, try to find a way to add it in the second step.
      formItems.push({name: level.value, text: level.text, value: '', dimension_weight: ''})

      // TODO: [refactor] Maybe move these lines in a more related file
      // margins for those levels should also be created
      if (!this.form.likert || this.form.kpi.likert === 'false') {
        const kpiMargins = this.form.kpi.kpi_margins_attributes
        // if it defaults to common or already exists skip
        const commonLevelIncluded = kpiMargins.findIndex(e => e.level === 'common') > -1
        const selectedLevelIncluded = kpiMargins.findIndex(e => e.level === level) > -1
        if (!commonLevelIncluded && !selectedLevelIncluded)
          kpiMargins.push({ level: level.value, m0: null, m1: null, m2: null, m3: null })
      }
    },
    removeAggregationLevel (level) {
      const formItems = this.form.aggregationLevels
      const index = formItems.findIndex(prop => prop.name === level.value)
      formItems.splice(index,1)

      // TODO: [refactor] Maybe move these lines in a more related file
      // margins for those levels should also be removed
      if (this.form.kpi.likert === 'false') {
        const kpiMargins = this.form.kpi.kpi_margins_attributes
        const marginIndex = kpiMargins.findIndex(item => item.level === level.value)
        if (marginIndex > -1) {
          const marginDbId = kpiMargins[marginIndex].id
          kpiMargins.splice(marginIndex, 1)
          // if in edit mode an extra item should be pushed to destroy the element in the db
          kpiMargins.push({ id: marginDbId, _destroy: 1 })
        }
      }
    },
    checkIfLikert () {
      if (this.selectedFunctionalUnit.includes('Likert Scale'))
        this.form.kpi.likert = 'true'
      else
        this.form.kpi.likert = 'false'
    },
    onChange ($event) {
      this.checkIfLikert()

      // clear margins attributes to start over
      this.form.kpi.kpi_margins_attributes = []
      
      this.$emit('name-changed', $event)
    },
    onChangeMutli(selected, id) {
      this.form.kpi.name = selected.value
      this.checkIfLikert()
      this.form.kpi.kpi_margins_attributes = []

      console.log(selected.value, id)
      this.$emit('name-changed', selected.value)
    },
    onValueChange ($event, index) {
      const strValue = this.form.aggregationLevels[index].value.toString()
      if (this.form.kpi.likert === 'true' && strValue.includes('.')) {
        this.form.aggregationLevels[index].value = parseInt(strValue)
      }
    },
  },

}
</script>

<style scoped>

</style>