<template>
  <div class="mt-2">
    <form>
      <input type="text" v-model="cloneProjectName" placeholder="Clone project name...">
      <button type="submit" class="btn btn-sm btn-primary" :disabled="disableButton" @click.prevent="onSubmit">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="showSpinner"></span>
        Submit
      </button>
    </form>
  </div>
</template>

<script>
export default {
  name: "CloneButtonForm",
  props: {
    projectId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      cloneProjectName: '',
      showSpinner: false
    }
  },
  computed: {
    disableButton: function() {
      return !this.cloneProjectName || this.showSpinner
    }
  },
  methods: {
    onSubmit() {
      this.showSpinner = true
      this.$inertia.post(this.$routes.clone_project(this.projectId), { project: { clone_name: this.cloneProjectName } })
    }
  }
}
</script>
