<template>
    <div v-if="colorTable === 'gradientColors'" class="average-scores-container">
        <div style="font-size: 16px; margin-bottom: 5px;"><strong>Color Code</strong></div>
        <div v-for="(level, index) in gradientColors" class="average-score">
            <div class="average-score-legend-line-medium" :style="`background: ${gradientColors[index]};`"/>
            <div style="font-size: 16px;">{{ getSuccessStrings(index) }}</div>
        </div>
        <div v-if="averageScoreOption" class="average-score">
            <div class="average-score-legend-line-medium" :style="`background: red`"/>
            <div style="font-size: 16px;">{{ legendItemTitle }}</div>
        </div>
    </div>
    <div v-else class="average-scores-container">
          <div style="font-size: 12px; margin-bottom: 5px;"><strong>Scores per Aggregation Level <span v-if="showFinalSII">*</span></strong></div>
          <div v-for="(level, index) in activeLevels" class="average-score-wide">
            <div class="average-score-legend-line" :style="`background: ${colors[index]};`"/>
            <div style="font-size: 12px;">{{ clearAndCapitalize(level) }} <br><span v-if="showFinalSII">(Final {{ averageType }} score: {{ averageScores[level.toLowerCase()].toFixed(2) }})</span></div>
          </div>
          <div v-if="showFinalSII" class="text-muted" style="font-size: 12px; clear: both">*Darker lines represent the final {{ averageType }} score.</div>
    </div>
</template>

<script>
    export default {
        props:
        {
            
            activeLevels: {
                type: Array,
                required: false
            },
            averageScores: {
                type: Object,
                required: false
            },
            averageScoreOption: {
                type: Boolean,
                default: false,
                required: false
            },
            colors: {
                type: Array,
                required: false,
            },
            colorTable:
            {
                type: String,
                required: true
            },
            gradientColors: {
                type: Array,
                required: false
            },
            legendItemTitle: {
                type: String,
                required: false
            },
            showFinalSII: {
                type: Boolean,
                default: false,
                required: false
            },
            isSpiOrSii: {
                type: String,
                required: false,
                default: 'sii'
            }
        },
        computed: {
            averageType () {
             if (this.isSpiOrSii === 'sii') return 'SII'
            else return 'SPI'
      }
         }
    }
</script>

<style scoped>

</style>