<template>
  <div class="info-icon-container" v-b-tooltip.hover :title="message">
    <b-icon-info-circle></b-icon-info-circle>
  </div>
</template>

<script>
export default {
  name: "InfoIcon",
  props: {
    message: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
  .info-icon-container {
    width: fit-content;
    display: inline-block;
  }
</style>