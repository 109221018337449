<template>
  <b-row class="form-step">
    <b-col>
      <label>Select Sectors <InfoIcon message="Zero or any number of sectors can be selected."></InfoIcon></label>
      <multiselect
          v-model="selectedSectors"
          :options="optionsSectors"
          :multiple="true"
          :close-on-select="true"
          :clear-on-select="false"
          :preserve-search="true"
          :custom-label="sectorOptionsLabels"
          placeholder="Pick aggregation levels"
          @select="addSector"
          @remove="removeSector"
      ></multiselect>
      <b-form-group>
        <b-row>
          <b-col cols="12" v-if="form.kpi.sectors_attributes.length > 0">
            <div style="margin-top: 15px;">
              <span class="text-muted">Enter a weight for each sector and aggregation level pair </span>
              <InfoIcon message="Enter values in range [0,1]. Non-normalized weights will be supported in the future."></InfoIcon>
            </div>
          </b-col>
          <b-col cols="12" v-for="(sector, index) in form.kpi.sectors_attributes" :key="sector.name">
            <hr>
            <span><strong>{{clearAndCapitalize(sector.name)}}</strong></span>
            <!--
            <b-form-checkbox
                :id="`${sector.name}_equal_w`"
                v-model="form.kpi.sectors_attributes[index].equal_weights"
                :name="`${sector.name}_equal_w`"
                value="true"
                unchecked-value="false"
                class="mt-2"
            >
              Equal weights
            </b-form-checkbox>
            -->

            <b-row class="mt-2 mb-2">
              <b-col
                  cols="3"
                  v-if="sector && (sector.equal_weights === 'false' || sector.equal_weights === null || sector.equal_weights == undefined)"
                  v-for="(level, index) in form.aggregationLevels"
                  :key="`${sector.name}_${level.name}`"
              >
                <span>
                  <strong>{{clearAndCapitalize(level.name)}}</strong>
                  <b-form-input
                      :id="`${level.name}_dimension_weight`"
                      v-model="form.aggregationLevels[index][`sector_${sector.name}_weight`]"
                      type="text"
                      :placeholder="getPlaceholder(index, `sector_${sector.name}_weight`)"
                      :readonly="form.aggregationLevels[index][`sector_${sector.name}_weight`] === null"
                      required
                  >
                  </b-form-input>


                  <div>
                    <span v-if="accumulatedWeights[sector.name] && parseFloat(accumulatedWeights[sector.name][level.name]) > 0">
                      <i
                          class="text-orange"
                      >
                        (accumulated weight:{{ accumulatedWeights[sector.name][level.name] }})
                      </i>
                    </span>
                    <i class="text-orange" v-else>(No accumulated weight)</i>
                  </div>
                </span>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import Multiselect from "vue-multiselect";
import StringManipulation from "@/Mixins/StringManipulation";
export default {
  name: "ThirdStep",
  components: {
    Multiselect
  },
  mixins: [StringManipulation],
  props: {
    value: {
      type: Object,
      required: true
    },
    accumulatedWeights: {
      type: Object,
      required: false,
      default: null
    },
    ignoredWeightsServer: {
      type: Array,
      required: false,
      default: function () {
        return []
      }
    }
  },
  computed: {
    form: {
      get() {
        return this.value
      },
    },
    selectedSectors: {
      get () {
        let arr = []
        const sectors = this.form.kpi.sectors_attributes
        for (let i = 0; i < sectors.length; i++) {
          arr.push(sectors[i].name)
        }
        return arr
      },
      set (val) {

      }
    }
  },
  data () {
    return {
      selectedSector: null,
      optionsSectors: ['climate_change_&_mitigation', 'quality_of_life_&_prosperity', 'resource_efficiency', 'smart_&_reliable_infrastructure'],
      optionsSector: [
        { value: null, text: 'Please select an aggregation level' },
        { value: 'climate_change_&_mitigation', text: 'Climate Change & Mitigation' },
        { value: 'quality_of_life_&_prosperity', text: 'Quality of Life & Prosperity' },
        { value: 'resource_efficiency', text: 'Resource Efficiency' },
        { value: 'smart_&_reliable_infrastructure', text: 'Smart & Reliable Infrastructure' },
      ],
      alreadySelectedSector: false,
      // a model for the check boxes of ignored buttons
      // because if there is not one then it starts unchecked and there is no way
      // to start it checked if the weights are ignored (edit page mainly)
      ignoredWeights: {},
      ignoredWeightsReady: false,
      reusedSectors: []
    }
  },
  methods: {
    addSector(sector) {
      const formItems = this.form.kpi.sectors_attributes
      const reusedIndex = this.reusedSectors.findIndex(prop => prop.name === sector)
      if(reusedIndex >= 0){
        formItems.push(this.reusedSectors[reusedIndex])
        this.reusedSectors.splice(reusedIndex,1)
      }
      else{
        formItems.push({name: sector, equal_weights: 'false'})
      }
      const hash = {}
      for (let i = 0; i < this.form.aggregationLevels.length; i++)
        hash[this.form.aggregationLevels[i]] = 'specify'
      this.$set(this.ignoredWeights, sector, hash)
    },
    removeSector(sector) {
      const formItems = this.form.kpi.sectors_attributes
      const index = formItems.findIndex(prop => prop.name === sector)
      this.reusedSectors.push(formItems[index])
      formItems.splice(index,1)
    },
    sectorOptionsLabels (v) {
      return v.replaceAll('_', ' ').replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
    },
    callOnChange(index, sector, value) {
      if (value === 'ignore')
        this.$set(this.form.aggregationLevels[index], sector, null)
      else
        this.$set(this.form.aggregationLevels[index], sector, '')
    },
    getPlaceholder (index, sector) {
      if (this.form.aggregationLevels[index][sector] === null)
        return 'Weight is ignored'
      return 'Enter weight'
    },
    // dimension param may represent sector too
    ignoreWeight (dimension, level) {
      return this.ignoredWeightsServer.filter(e => e.dimension.toLowerCase() === dimension && e.level === level.toLowerCase()).length > 0
    }
  },
  mounted () {
    // set the model for checkboxes of ignored weights
    const levels = this.form.aggregationLevels
    const checkboxes = this.ignoredWeights
    for (let i = 0; i < levels.length; i++) {
      const level = levels[i]
      const levelName = level.name
      const keys = Object.keys(level)

      for (let k = 0; k < keys.length; k++) {
        const key = keys[k]

        if (key.includes('sector_')) {
          const weight = level[key]
          let isIgnored = 'specify'
          if (weight === null) isIgnored = 'ignore'
          const cleanKey = key.replace('sector_', '').replace('_weight', '')
          let hash = {}
          if (checkboxes[cleanKey]) hash = checkboxes[cleanKey]
          hash[levelName] = isIgnored
          this.$set(checkboxes, cleanKey, hash)
        }
      }
    }
    this.ignoredWeightsReady = true

    // find if there are null but something has changed in ignore weights
    // e.g. saved a kpi when mobility-building was being ignored
    // but the user changes this preference so it has to be '' from null
    const aggLevels = this.form.aggregationLevels
    for (let i = 0; i < aggLevels.length; i++) {
      const keys = Object.keys(aggLevels[i]).filter(e => e.includes('sector_'))
      for (let j = 0; j < keys.length; j++) {
        const sKey = keys[j]
        if (aggLevels[i][sKey] === null) {
          const sectorName = sKey.replace('sector_', '').replace('_weight', '')
          const levelName = sKey['name']
          const item = this.ignoredWeightsServer.filter(e => e.dimension === sectorName && e.level === levelName)[0]
          if (!item) aggLevels[i][sKey] = ''
        }
      }
    }

    // ---- new from server ignored weights
    for (let i = 0; i < this.ignoredWeightsServer.length; i++) {
      const item = this.ignoredWeightsServer[i]
      const alItem = this.form.aggregationLevels.filter(e => e.name === item.level)[0]
      if (alItem) {
        alItem[`sector_${item.dimension}_weight`] = null
      }

      if (this.ignoredWeights[item.dimension] !== undefined)
        this.ignoredWeights[item.dimension][item.level] = 'ignore'
    }
  }
}
</script>

<style scoped>

</style>