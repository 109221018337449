<!-- 
This component is used to display the KPIs that are associated with a specific project
It is used in the projects_controller.rb and its route is kpis_overview_project(:project_id)
 Props: 
 - `project` (Object): Contains the details of the project being displayed.
    - Default: null
    - Required: false
    - Usage: It is used to display the project name in the table header and provide a back link to the project's page.
 
 - `projectKpis` (Array): An array of KPIs related to the project.
    - Default: []
    - Required: false
    - Usage: This prop is used to generate the rows in the KPI table. It displays details such as KPI ID and name, and allows editing of each KPI.
 
 - `aggregationLevelValues` (Array): An array of values for the KPIs. Each result associates a KPI with an aggregation level and its value.
    - Default: []
    - Required: false
    - Usage: It is used in the `retrieveKpiValue` method to retrieve the correct KPI value for each aggregation level in the table.

 - `aggregationLevels` (Array): An array containing the unique aggregation levels to be displayed as column headers in the table.
    - Default: []
    - Required: false
    - Usage: This prop is used to dynamically generate the column headers in the table, where each name is formatted and displayed using the `formatKpiName` method.
-->
<template>
  <b-container>
    <div class="table-wrapper">
      <!-- Dynamic Table -->
      <table border="1" cellspacing="0" cellpadding="8">
        <thead>
          <tr>
            <th colspan="7">{{ project.name }} - KPIs</th>
          </tr>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th
              class="text-center"
              v-for="aggregation_level in aggregationLevels"
              :key="aggregation_level"
            >
              {{ formatKpiName(aggregation_level) }}
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="kpi in projectKpis" :key="kpi.id">
            <td>{{ kpi.id }}</td>
            <td>{{ formatKpiName(kpi.name) }}</td>
            <td
              class="text-center"
              v-for="aggregation_level in aggregationLevels"
              :key="aggregation_level"
            >
              <span v-if="retrieveKpiValue(kpi.id, aggregation_level)">
                {{ retrieveKpiValue(kpi.id, aggregation_level).value }}
              </span>
              <span v-else>-</span>
            </td>
            <td>
              <inertia-link
                :href="$routes.edit_project_kpi(project.id, kpi.id)"
              >
                Edit KPI
              </inertia-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <UpdateValuesModal :project-id="project.id" :errors="errors"></UpdateValuesModal>

    <b-row class="mt-2">
      <b-col>
        <inertia-link :href="$routes.project(project.id)">Back to project</inertia-link>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import MainLayout from '@/Layouts/Main'
import UpdateValuesModal from "./subcomponents/UpdateValuesModal.vue";

export default {
  components: {UpdateValuesModal},
  layout: MainLayout,
  props: {
    project: {
      type: Object,
      default: null,
      required: false,
    },
    projectKpis: {
      type: Array,
      default: () => [],
      required: false,
    },
    aggregationLevelValues: {
      type: Array,
      default: () => [],
      required: false,
    },
    aggregationLevels: {
      type: Array,
      default: () => [],
      required: false,
    },
    errors: {
      type: Array,
      default: [],
      required: false
    }
  },
  created() {},
  computed: {
    mFields() {
      // Extract unique m fields dynamically
      const fields = ["m0", "m1", "m2", "m3"];
      // Check if any KPI has non-null values for these fields
      return fields.filter((field) =>
          this.projectKpis.some((kpi) => kpi[field] !== null)
      );
    },
  },
  methods: {
    formatKpiName(name) {
      return name
        .split("_") // Split the string by underscores
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
        .join(" "); // Join the words with spaces
    },
/**
 * Finds the value of a KPI in an aggregation level.
 *
 * This method searches through the `aggregationLevelValues` array to find a value
 * that matches both the provided KPI ID (`kpiId`) and the aggregation_level name (`aggregation_level`).
 *
 * @param {Number} kpiId - The ID of the KPI for which the value is being searched.
 * @param {String} aggregation_level - The name of the aggregation_level for which the KPI value is needed.
 * 
 * @returns {Object|undefined} - Returns the result object if a match is found, otherwise returns `undefined`.
 * The result object is expected to have properties like `kpi_id`, `name`, and `value` (the actual KPI result value).
 * 
 * Example result object format:
 * {
 *   kpi_id: 244,            // ID of the KPI
 *   name: 'City',          // Name of the aggregation_level
 *   value: 75             // Value of the KPI for this aggregation_level
 * }
 * 
 */
    retrieveKpiValue(kpiId, aggregation_level) {
      return this.aggregationLevelValues.find((result) => {

        return result.kpi_id === kpiId && result.name === aggregation_level;
      });
    },
  },
};
</script>

<style scoped>
/* Add this style to make the table scrollable */
.table-wrapper {
  max-height: 800px; 
  overflow-y: auto; 
  overflow-x: auto; 
  border: 1px solid #ddd;
  display: block;
}

thead th {
  position: sticky;
  top: 0; 
  background-color: #f8f9fa;
  z-index: 1; 
}


table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
}

th,
td {
  padding: 12px 15px;
  text-align: left;
  border: 1px solid #ddd; 
}

th {
  background-color: #f8f9fa; 
  font-weight: bold;
  text-align: center;
}

td {
  text-align: center;
  vertical-align: middle;
}

tbody tr:nth-child(even) {
  background-color: #f9f9f9; 
}

tbody tr:hover {
  background-color: #f1f1f1; 
}

td:last-child {
  white-space: nowrap;
}

.table-action-links {
  color: #007bff;
  margin-right: 10px;
}

.table-action-links:hover {
  color: #0056b3;
}

button {
  background-color: #6c757d; 
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #5a6268;
}
</style>
