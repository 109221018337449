<template>
  <b-table
    striped
    hover
    bordered
    responsive
    :fields="headers"
    :items="items"
    :class="[getHeaderClass(color), 'indicators-table']"
  >
    <template #cell(name)="data">
      {{ clearAndCapitalize(data.item.name) }} <span v-if="data.item.label">({{data.item.label}})</span>
    </template>
    <template #cell(created_at)="data">
      {{ new Date(data.item.created_at).toLocaleString() }}
    </template> 
    <template #cell(updated_at)="data">
      {{ new Date(data.item.updated_at).toLocaleString() }}
    </template>
    <template #cell(aggregation_levels)="data">
      {{ data.item.aggregation_levels.map(l => clearAndCapitalize(l)).join(', ') }}
    </template>
    <template #cell(action)="data">
      <inertia-link :href="getShowUrl(data.item.id)" class="text-primary">Open</inertia-link>
      |
      <inertia-link :href="getEditUrl(data.item.id)" class="text-success">Edit</inertia-link>
      |
      <a href="#" @click="onIndicatorDelete(data.item.id)" class="text-orange">Remove</a>
    </template>

  </b-table>
</template>

<script>
import data from "bootstrap/js/src/dom/data";

export default {
  name: "IndicatorsTable",
  computed: {
    data() {
      return data
    }
  },
  props: {
    projectId: {
      type: Number,
      required: true
    },
    indicatorType: {
      type: String,
      required: true
    },
    headers: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    color: {
      type: String,
      required: false
    }
  },
  methods: {
    getHeaderClass () {
      switch (this.color) {
        case 'sand':
          return 'sand-header-table'
        case 'dark':
          return 'dark-header-table'
      }
    },
    getShowUrl (indicatorId) {
      switch (this.indicatorType) {
        case 'kpi':
          return this.$routes.project_kpi(this.projectId, indicatorId)
        case 'psi':
          return this.$routes.project_psi(this.projectId, indicatorId)
      }
    },
    getEditUrl (indicatorId) {
      switch (this.indicatorType) {
        case 'kpi':
          return this.$routes.edit_project_kpi(this.projectId, indicatorId)
        case 'psi':
          return this.$routes.edit_project_psi(this.projectId, indicatorId)
      }
    },
    onIndicatorDelete (indicatorId) {
      switch (this.indicatorType) {
        case 'kpi':
          this.$inertia.delete(this.$routes.project_kpi(this.projectId, indicatorId), {
            onBefore: () => confirm('Are you sure you want to delete this KPI?'),
          })
          break
        case 'psi':
          this.$inertia.delete(this.$routes.project_psi(this.projectId, indicatorId), {
            onBefore: () => confirm('Are you sure you want to delete this PSI?'),
          })
          break
      }
    }
  }
}
</script>
<style scoped>

</style>