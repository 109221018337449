<template>
  <b-row v-if="form.level !== undefined && selectedLevel.toLowerCase() === form.level.toLowerCase()">
    <hr>
    <b-col cols="4" v-for="index in [0, 1, 2, 3]" class="mb-3" :key="`m_${form.level}_${index}`">
      <b-form-group
          :id="`kpi_m_${form.level}_${index}_input`"
          :label="getLabel(index)"
          :label-for="`kpi_m_${form.level}_${index}`"
          :style="getColor(index)"
          class="likert-input"
      >
        <b-form-input
            :id="`kpi_m_${form.level}_${index}`"
            v-model="form[`m${index}`]"
            type="number"
            placeholder="Enter margin"
            step="0.001"
            min="-1000000.00"
            max="1000000.00"
            required
        ></b-form-input>
      </b-form-group>
    </b-col>

    <b-col cols="4" class="mb-3">
      <b-form-group
          :id="`kpi_m_${form.level}_${4}_input`"
          :label="getLabel(4)"
          :label-for="`kpi_m_${form.level}_${4}`"
          :style="getColor(4)"
          class="likert-input"
      >
        <b-form-input
            :id="`kpi_m_${form.level}_${4}`"
            :value="m4"
            type="text"
            placeholder="autocompleted"
            readonly
        ></b-form-input>
      </b-form-group>
    </b-col>

    <hr>

    <b-col cols="12">
      <div v-if="monotonous === 'error'" style="color: red">Wrong margins</div>

      <MarginsDistributionPlot
          v-if="monotonous === 'asc' || monotonous === 'desc'"
          :values="[form.m0, form.m1, form.m2, form.m3]"
      ></MarginsDistributionPlot>

      <span v-else>The distribution plot will be generated after you have given all margin inputs.</span>
    </b-col>
  </b-row>
</template>

<script>
import MarginsDistributionPlot from "@/Shared/MarginsDistributionPlot";

export default {
  name: "MarginsInput",
  components: {
    MarginsDistributionPlot
  },
  props: {
    // form.kpi.kpi_margins_attributes[index]
    value: {
      type: Object,
      required: true
    },
    selectedLevel: {
      type: String,
      required: true
    }
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    m4: function () {
      if (this.monotonous === 'asc') return `>= ${this.form.m3}`
      else if (this.monotonous === 'desc') return `<= ${this.form.m3}`
      else return null
    },
    monotonous: function () {
      const kpi = this.form
      return this.isMonotonic([kpi.m0, kpi.m1, kpi.m2, kpi.m3])
    }
  },
  methods: {
    getLabel(index) {
      if (index === 0)
        return 'Failed'
      else if (index === 1)
        return 'Underachieved'
      else if (index === 2)
        return 'Achieved'
      else if (index === 3)
        return 'Overachieved'
      else
        return 'Excelled'
    },
    getColor(index) {
      if (index === 0)
        return 'background: rgb(255 72 72);'
      else if (index === 1)
        return 'background: #ffa700;'
      else if (index === 2)
        return 'background: #fff400;'
      else if (index === 3)
        return 'background: rgb(125 195 0);'
      else
        return 'background: #2cba00;'
    }
  }
}
</script>

<style scoped>

</style>