<template>
  <div>
    <PsiForm v-model="form" :project-id="projectId" :psi-types="psiTypes" :default-psi-values="defaultPsiValues" @submit="submit" />
  </div>
</template>

<script>
import MainLayout from '@/Layouts/Main'
import PsiForm from './Form'
export default {
  name: "New",
  layout: MainLayout,
  components: {
    PsiForm
  },
  props: {
    projectId: {
      type: Number,
      required: true
    },
    psi: {
      type: Object,
      required: true
    },
    psiTypes: {
      type: Array,
      required: true
    },
    defaultPsiValues: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      form: this.psi
    }
  },
  methods: {
    submit() {
      this.$inertia.post(this.$routes.project_psis(this.projectId), this.form)
    }
  }
}
</script>

<style scoped>

</style>