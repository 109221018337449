<template lang="html">
  <div>
    <b-container>
      <b-row>
        <b-col>
          <h2>{{ kpi.id }}. {{ clearAndCapitalize(kpi.name) }}</h2>
          <span v-if="!kpi.state" class="text-danger text-decoration-underline">Incomplete state</span>
          <hr>
          <b-table
              striped
              hover
              v-if="valuePerLevel.length > 0"
              :items="valuePerLevel"
              class="indigo-header-table"
              caption-top
              caption="Value per aggregation level"
          ></b-table>
          <div v-else>No values per aggregation level</div>

          <NestedHeaderTable
              v-if="dimension.name || sectors.length > 0"
              :levels="aggregationLevels"
              :sectors="sectors"
              :dimension="dimension"
          ></NestedHeaderTable>
          <div v-else>No dimensions nor sectors specified</div>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col v-if="kpi.likert === true">
          <hr>
          <h5>Margins</h5>
          <i>Likert scale is used</i>
        </b-col>
        <b-col v-else-if="margins.length > 0" cols="12">
          <hr>
          <h5>Margins</h5>

          <div v-if="!(selectedLevel === 'common')">
            <div>Please select an aggregation level:</div>
            <b-form-select
                v-model="selectedLevel"
                :options="optionsLevels"
            ></b-form-select>
          </div>
          <b-table striped hover :items="scale" class="indigo-header-table"></b-table>
        </b-col>
        <b-col v-else>
          <div>No margins specified</div>
        </b-col>
      </b-row>

      <b-row>
        <hr>
        <b-col>
          <inertia-link :href="$routes.project(projectId)">Back to project</inertia-link>
          |
          <inertia-link :href="$routes.edit_project_kpi(projectId, this.kpi.id)">Edit</inertia-link>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import MainLayout from '@/Layouts/Main'
import NestedHeaderTable from "@/Shared/NestedHeaderTable";
import MarginsFunctions from "@/Mixins/MarginsFunctions";

export default {
  layout: MainLayout,
  components: {
    NestedHeaderTable
  },
  mixins: [MarginsFunctions],
  props: {
    projectId: {
      type: Number,
      required: true
    },
    kpi: {
      type: Object,
      required: true
    },
    aggregationLevels: {
      type: Array,
      required: true
    },
    sectors: {
      type: Array,
      required: true
    },
    dimension: {
      type: Object,
      required: true
    },
    margins: {
      type: Array,
      required: true
    }
  },
  computed: {
    scale: function () {
      if (this.selectedLevel !== '') {
        const marginsIndex = this.margins.findIndex(m => m.level === this.selectedLevel)
        const margins = this.margins[marginsIndex]
        return this.showPageMargins([margins['m0'], margins['m1'],
          margins['m2'], margins['m3']])
      }
    },
    valuePerLevel: function () {
      if (!this.kpi.state && this.dimension.agg.length === 0) return []
      let arr = []
      for (let i = 0; i < this.aggregationLevels.length; i++)
        arr.push({
          level: this.clearAndCapitalize(this.aggregationLevels[i]),
          value: this.dimension.agg.find(l => l.name === this.aggregationLevels[i]).value
        })
      return arr
    },
    optionsLevels () {
      return this.aggregationLevels
    }
  },
  data() {
    return {
      selectedLevel: ''
    }
  },
  mounted () {
    if (this.margins.length > 0) {
      this.selectedLevel = this.margins[0].level
    }
  }
}
</script>

<style lang="css" scoped>
</style>
