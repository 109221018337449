<template>
  <div class="select-chart-container" :id="id">
    <b-row>
      <b-col cols="5" class="mb-2 m-2">
          <FormSelect
            :availableItems="availableItems"
            :selectedItem="selectedRadarItem"
            :selectPrompt="selectPrompt"
            @update-data="onRadarChange" 
            ></FormSelect>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <SimpleRadarChart
            v-if="selectedRadarItem"
            :title="title"
            :chartData="itemsData[selectedRadarItem]"
        ></SimpleRadarChart>
      </b-col>
    </b-row>
    <b-button @click="printChart(id)" style="margin-left:10px">Download graph</b-button>
  </div>
</template>

<script>
import SimpleRadarChart from "../simple/SimpleRadarChart";
import FormSelect from "../components/FormSelect";
import Exporter from "vue-chartjs-exporter";

export default {
  name: "RadarChartWithSelect",
  components: {
    SimpleRadarChart,
    FormSelect,
    Exporter
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'Untitled Chart'
    },
    selectPrompt: {
      type: String,
      required: false,
      default: 'Please select an item from the list'
    },
    itemsData: {
      type: Object,
      required: true
    },
    id:{
      type: String,
      required: false
    }
  },
  data () {
    return {
      selectedRadarItem: null
    }
  },
  computed: {
    availableItems () {
      let arr = []
      for (let key in this.itemsData) arr.push(key)
        arr.sort()
      return arr
     }
  },
  mounted () {
    this.selectedRadarItem = this.availableItems[0]
    this.onRadarChange(this.selectedRadarItem)
  },
  methods: {
    onRadarChange (value) {
      this.selectedRadarItem = value
    },
    printChart(id)
  {
    let graphical = document.getElementById(id);
    const exp = new Exporter([graphical]);
    exp.export_pdf().then((pdf) => pdf.save("charts.pdf"));
  }
  }
}
</script>

<style scoped>

</style>