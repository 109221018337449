<template>
  <div>
    <h2 class="text-center">SII & SPI Analysis</h2>
    <b-row v-if="pythonErrors">
      <b-col>
        <b-list-group class="mb-2">
          <b-list-group-item variant="danger">
            {{ pythonErrors.errors }}
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col cols="6 mb-3">
        <SimpleVerticalBarChart
            v-if="finalSiiChartData"
            :chartData="finalSiiChartData"
            :gradientColors="['#18abdd', '#1de5bc', '#ea7369', '#e7e44f']"
            :axisLabels="{ x: 'Aggregation Level', y: 'Score' }"
            :showColorCode="false"
            :aspectRatio="{ width: 2, height: 1 }"
            title="Final SII Scores"
            id="graph1"
        >
        </SimpleVerticalBarChart>
      </b-col>

      <b-col cols="6 mb-3">
        <SimpleVerticalBarChart
            v-if="finalSpiChartData"
            :chartData="finalSpiChartData"
            :gradientColors="['#18abdd', '#1de5bc', '#ea7369', '#e7e44f']"
            :axisLabels="{ x: 'Aggregation Level', y: 'Score' }"
            :showColorCode="false"
            :aspectRatio="{ width: 2, height: 1 }"
            title="Final SPI Scores"
            id="graph2"
        >
        </SimpleVerticalBarChart>
      </b-col>

      <b-col cols="6 mb-3">
        <RadarChartWithSelect
          title="Dimensions score per Aggregation Level"
          selectPrompt="Please select an aggregation level"
          :itemsData="radarChartData"
          id="graph3"
        >
        </RadarChartWithSelect>
      </b-col>

      <b-col cols="6 mb-3">
        <RadarChartWithSelect
            title="Sectors score per Aggregation Level"
            selectPrompt="Please select an aggregation level"
            :itemsData="sectorsRadarChartData"
            id="graph4"
        >
        </RadarChartWithSelect>
      </b-col>

      <b-col class="mb-3">
        <ChartWithSelect
            :availableItems="availableDimensions"
            :itemsData="dimensionData"
            :averageScores="averageScores"
            :showFinalSII="true"
            selectPrompt="Select a dimension from the list"
            id="graph5"
        ></ChartWithSelect>
      </b-col>

      <b-col class="mb-3">
        <ChartWithSelect
            :availableItems="availableSectors"
            :itemsData="sectorsData"
            :averageScores="finalSpiScores"
            :showFinalSII="true"
            isSpiOrSii="spi"
            selectPrompt="Select a sector from the list"
            id="graph6"
        ></ChartWithSelect>
      </b-col>

      <b-col cols="12">
        <HorizontalBarChartWithSelect
            :availableItems="Object.keys(kpisData)"
            :itemsData="kpisData"
            :averageScores="averageScores"
            :gradientColors="kpisGradientColors"
            chartType="horizontal"
            selectPrompt="Select a KPI from the list"
            :axisLabels="{x: 'Score', y: ''}"
            :key="`bar_key_${horizontalBarKey}`"
            id="graph7"
        ></HorizontalBarChartWithSelect>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <inertia-link
            :href="this.$routes.project(projectId)"
        >Back to project</inertia-link>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import MainLayout from '@/Layouts/Main'
import ChartWithSelect from "@/Charts/select/ChartWithSelect";
import SimpleVerticalBarChart from "@/Charts/simple/SimpleVerticalBarChart";
import SimpleRadarChart from "@/Charts/simple/SimpleRadarChart";
import RadarChartWithSelect from "@/Charts/select/RadarChartWithSelect";
import SimpleBarChart from "@/Charts/simple/SimpleBarChart";
import HorizontalBarChartWithSelect from "@/Charts/select/HorizontalBarChartWithSelect";

export default {
  name: "KpisAnalysis",
  layout: MainLayout,
  components: {
    ChartWithSelect,
    SimpleVerticalBarChart,
    SimpleRadarChart,
    RadarChartWithSelect,
    SimpleBarChart,
    HorizontalBarChartWithSelect,
  },
  props: {
    availableDimensions: {
      type: Array,
      default: null,
      required: false
    },
    dimensionData: {
      type: Object,
      default: null,
      required: false
    },
    availableSectors: {
      type: Array,
      default: null,
      required: false
    },
    sectorsData: {
      type: Object,
      default: null,
      required: false
    },
    averageScores: {
      type: Object,
      default: null,
      required: false
    },
    kpisData: {
      type: Object,
      required: true
    },
    projectId: {
      type: Number,
      default: null,
      required: false
    },
    pythonErrors: {
      type: Object,
      default: null,
      required: false
    },
    kpisGradientColors: {
      type: Array,
      required: true
    },
    finalSpiScores: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      selectedRadarItem: null,
      horizontalBarKey: 1,
    }
  },
  computed: {
    finalSiiChartData () {
      const colors = ['#18abdd', '#1de5bc', '#ea7369', '#e7e44f']
      let labels = []
      let data = []

      // TODO: This should go to server side
      let keys = ['building', 'peb', 'ped', 'city']
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i]
        labels.push(this.clearAndCapitalize(key))
        data.push(this.averageScores[key])
      }

      return {
        labels: labels,
        datasets: [{
          label: 'Final SII Scores',
          data: data,
          backgroundColor: colors,
        },]
      }
    },
    finalSpiChartData () {
      const colors = ['#18abdd', '#1de5bc', '#ea7369', '#e7e44f']
      let labels = []
      let data = []

      // TODO: This should go to server side
      let keys = ['building', 'peb', 'ped', 'city']
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i]
        labels.push(this.clearAndCapitalize(key))
        data.push(this.finalSpiScores[key])
      }

      return {
        labels: labels,
        datasets: [{
          label: 'Final SPI Scores',
          data: data,
          backgroundColor: colors,
        },]
      }
    },
    // This method is used to inverse the data of the first bar graph
    // Generates data per dimension for each aggregated level
    // { "City": { labels: ["dimension1", "dimension2", ..., "dimensionN], data: [score1, score2, ..., scoreN] }}
    radarChartData () {
      let radarDataHash = {}
      for (let dKey in this.dimensionData) {
        const dimension = this.dimensionData[dKey]
        const labels = dimension.data.labels
        const data = dimension.data.datasets[0].data
        const length = data.length

        for (let i = 0; i < length; i++) {
          const label = labels[i]
          // if the key does not exist
          if (!radarDataHash[label]) {
            radarDataHash[label] = {
              labels: [],
              datasets: [{
                label: this.clearAndCapitalize(label),
                data: [],
                fill: true,
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgb(255, 99, 132)',
                pointBackgroundColor: 'rgb(255, 99, 132)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgb(255, 99, 132)'
              }]
            }
          }
          radarDataHash[label].labels.push(this.clearAndCapitalize(dKey))
          radarDataHash[label].datasets[0].data.push(data[i])
        }
      }
      // return radarDataHash
      return radarDataHash
    },
    sectorsRadarChartData () {
      let radarDataHash = {}
      for (let dKey in this.sectorsData) {
        const sector = this.sectorsData[dKey]
        const labels = sector.data.labels
        const data = sector.data.datasets[0].data
        const length = data.length

        for (let i = 0; i < length; i++) {
          const label = labels[i]
          // if the key does not exist
          if (!radarDataHash[label]) {
            radarDataHash[label] = {
              labels: [],
              datasets: [{
                label: this.clearAndCapitalize(label),
                data: [],
                fill: true,
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgb(255, 99, 132)',
                pointBackgroundColor: 'rgb(255, 99, 132)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgb(255, 99, 132)',
              }]
            }
          }
          radarDataHash[label].labels.push(this.clearAndCapitalize(dKey.replaceAll('_', ' ')))
          radarDataHash[label].datasets[0].data.push(data[i])
        }
      }
      // return radarDataHash
      return radarDataHash
    }
  },
  methods: {
    onRadarChange (value) {
      this.selectedRadarItem = value
    }
  },
  mounted() {
    const obj = this
    window.addEventListener('resize', function() {
      if (obj.horizontalBarKey === 1) obj.horizontalBarKey = 0
      else obj.horizontalBarKey = 1
    })
  }
}
</script>

<style scoped>

</style>
