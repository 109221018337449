<template>
  <div class="info-icon-container" v-b-tooltip.hover :title="message">
    <b-icon icon="exclamation-triangle-fill" scale="1.5" variant="warning"></b-icon>
  </div>
</template>

<script>
export default {
  name: "WarningIcon",
  props: {
    message: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
  .info-icon-container {
    width: fit-content;
    display: inline-block;
  }
</style>