<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">
      Home Page
    </h1>

    <p class="mb-8 leading-normal">
      Hey there! Welcome to the new Energy Evaluation Toolkit. Developed by CERTH/CPERI and designed to help with KPIs evaluation.
      The toolkit is being developed using

      <a
        class="text-indigo-800 underline hover:text-orange-600 font-bold"
        href="https://inertiajs.com"
      >Inertia.js</a> and
      <a
        href="https://rubyonrails.org/"
        class="text-indigo-800 underline hover:text-orange-600 font-bold"
      >Ruby on Rails.
    </a>.
    </p>
    <hr>
    <div style="width: 50%; margin: auto; text-align: center">
      <inertia-link :href="this.$routes.projects()">
        <b-button class="btn-lg btn-deep-blue" style="width: 100%; margin: auto;">Browse your projects</b-button>
      </inertia-link>
    </div>
  </div>
</template>

<script>
import Layout from '@/Layouts/Main'
export default {
  layout: Layout
}
</script>

<style lang="css" scoped>
</style>
