<template>
  <div class="form-step">
    <b-row>
      <b-col cols="6" class="mb-2">
        <b-form-group label="Name">
          <b-form-select
              v-model="form.name"
              :options="psiTypes"
              :state="validation"
              class="large-select-form rounded"
              @change="onChange"
          ></b-form-select>
          <b-form-invalid-feedback :state="validation">
            Please select a name from the list.
          </b-form-invalid-feedback>
          <b-form-valid-feedback :state="validation">
            Name selected.
          </b-form-valid-feedback>
        </b-form-group>
        <span v-if="selectedFunctionalUnit" style="font-size: 14px;"><strong >Functional Unit: </strong>{{ selectedFunctionalUnit }}</span>
      </b-col>
      <hr>
    </b-row>

    <b-row>
      <b-col cols="2">
        <b-form-group>
          <template v-slot:label>
            <label>Call Impact <InfoIcon message="The call impact number given in the proposal."></InfoIcon></label>
          </template>
          <b-form-select
              v-model="form.call_impact"
              :options="optionsForCi"
              class="large-select-form rounded"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col cols="3">
        <b-form-group>
          <template v-slot:label>
            <label>Label <InfoIcon message="Please enter a real value"></InfoIcon></label>
          </template>
          <b-form-input
              id="psi_label"
              v-model="form.label"
              type="string"
              placeholder="Enter value"
              required
          ></b-form-input>
        </b-form-group>
      </b-col>

      <b-col cols="5">
        <b-form-group
            id="psi_value_group"
        >
          <template v-slot:label>
            <label>Value <InfoIcon message="Please enter a real value"></InfoIcon></label>
          </template>
          <b-form-input
              id="psi_value"
              v-model="form.value"
              type="number"
              placeholder="Enter value"
              required
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "FirstStep",
  props: {
    value: {
      type: Object,
      required: true
    },
    psiTypes: {
      value: Array,
      required: true
    }
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    validation() {
      return this.form.name !== null
    },
    selectedFunctionalUnit () {
      const obj = this.psiTypes.find(type => type.value === this.form.name)
      if (obj !== undefined)
        return obj.functionalUnit
      return null
    }
  },
  data () {
    return {
      optionsForName: [
        { value: null, text: 'Please select an option' },
        { value: 'number_of_pebs_deployed_at_the_end_of_pocityf', text: 'number_of_pebs_deployed_at_the_end_of_pocityf' },
        { value: 'number_of_peds-pebs_deployed_due_to_pocityf_until_2030', text: 'number_of_peds-pebs_deployed_due_to_pocityf_until_2030' },
        { value: 'number_of_cities_with_a_secap_approved_and_implemented', text: 'number_of_cities_with_a_secap_approved_and_implemented' },
        { value: 'total_local_res_produced_within_the_pebs', text: 'total_local_res_produced_within_the_pebs', }
      ],
      optionsForCi: [
        { value: null, text: 'No Call Impact' },
        { value: '', text: '- Call Impact Numbers -', disabled: true},
        { value: '1', text: 'Call Impact #1'},
        { value: '2', text: 'Call Impact #2'},
        { value: '3', text: 'Call Impact #3'},
        { value: '4', text: 'Call Impact #4'},
        { value: '5', text: 'Call Impact #5'},
        { value: '6', text: 'Call Impact #6'},
        { value: '7', text: 'Call Impact #7'},
      ]
    }
  },
  methods: {
    onChange ($event) {
      this.$emit('name-changed', $event)
    }
  }
}
</script>

<style scoped>

</style>