<template>
  <div class="p-2 ml-2 my-2 rounded" style="background: #ffffff;">
    <form>
      <div class="d-inline-block bg-white rounded border border-dark">
        <input class="bg-white p-2 rounded" type="file" ref="fileInput" @change="handleFileUpload"/>
      </div>

      <button type="submit" class="btn btn-sm btn-primary" :disabled="!csvFile" @click.prevent="onSubmit">Submit</button>
      <button type="button" class="btn btn-sm btn-secondary" @click="onCancel" v-if="csvFile">Cancel</button>

      <a :href="templateDownloadPath" :download="templateDownloadName">
        <button type="button" class="btn btn-sm btn-primary" @click=""> Download {{ method.toUpperCase() }}s Template </button>
      </a>

      <div class="text-success mt-1 px-2" v-if="csvFile && !errors">File selected. Click submit to update the values.</div>
      <div class="text-danger mt-1 px-2" v-if="errors">{{ errors }}</div>
    </form>
  </div>
</template>

<script>
export default {
  name: "UpdateValuesModal",
  props: {
    projectId: Number,
    method: { type: String, default: 'kpi' },
    errors: {
      type: Array,
      default: [],
      required: false
    }
  },
  data() {
    return {
      csvFile: null,
      filepath: '_values_upload_template.csv'
    }
  },
  computed: {
    templateDownloadPath: function () {
      return `/import-templates/${this.method}${this.filepath}`
    },
    templateDownloadName: function () {
      return `${this.method}${this.filepath}`
    }
  },
  methods: {
    handleFileUpload(e) {
      this.csvFile = e.target.files[0]
    },
    onSubmit(e) {
      const formData = this.$inertia.form({
        uploaded_csv_file: this.csvFile
      })
      let route = this.$routes.update_kpis_values_project_kpis(this.projectId)
      if(this.method === 'psi') {
        route = this.$routes.update_psis_values_project_psis(this.projectId)
      }
      formData.post(route)
      this.onCancel()
    },
    onCancel() {
      this.csvFile = null
      this.$refs.fileInput.value = null;
    }
  },
}
</script>

<style scoped>

</style>