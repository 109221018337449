// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

import { App, plugin } from '@inertiajs/inertia-vue'
import Vue from 'vue'
import { InertiaProgress } from '@inertiajs/progress'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import * as Routes from "./routes"
import axios from 'axios'
import "stylesheets/application"
import "bootstrap/dist/js/bootstrap.bundle";
import StringManipulation from "../Mixins/StringManipulation";
import Utils from "../Mixins/Utils";
import InfoIcon from "../Shared/InfoIcon";
import WarningIcon from "../Shared/WarningIcon";

InertiaProgress.init()

Vue.use(plugin)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.component('InfoIcon', InfoIcon)
Vue.component('WarningIcon', WarningIcon)
Vue.prototype.$routes = Routes
Vue.mixin(StringManipulation)
Vue.mixin(Utils)

axios.defaults.xsrfHeaderName = "X-CSRF-Token"

const el = document.getElementById('app')

new Vue({
  render: h => h(App, {
    props: {
      initialPage: JSON.parse(el.dataset.page),
      resolveComponent: name => require(`../Pages/${name}`).default,
    },
  }),
}).$mount(el)
