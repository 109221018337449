<template>
  <div class="login">
    <nav class="navbar fixed-top navbar-expand-lg navbar-dark bg-dark transparent">
  <div class="container-fluid">
    <inertia-link class="navbar-brand " :href="$routes.root()"><img class="imgHead" src="../../../assets/images/logos/cperi.png" alt="cperi" width="60" height="60"></inertia-link>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <inertia-link class="nav-link" :href="$routes.root()">Home</inertia-link>
        </li>
        <li class="nav-item">
          <inertia-link class="nav-link" :href="$routes.projects()">Projects</inertia-link>
        </li>
        <!-- <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Default KPIs/SPIs
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><inertia-link  class="dropdown-item" :href="this.$routes.kpi_types()">KPIs</inertia-link></li>
            <li><inertia-link  class="dropdown-item" :href="this.$routes.psi_types()">PSIs</inertia-link></li>
          </ul>
        </li> -->
        <li class="nav-item">
          <inertia-link class="nav-link" :href="$routes.user_manual()">Manual</inertia-link>
        </li>
      </ul>
    </div>
  </div>
</nav>
    <!-- <div class="login-container">
      <div class="login-form my-auto">
        <FlashMessages />
        <b-form @submit.prevent="form.post($routes.user_session())">
          <b-form-group id="input-group-1" label="Email address:" label-for="email">
            <b-form-input id="email" v-model="form.user.email" type="email" placeholder="Enter email" required></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-2" label="Password:" label-for="input-2">
            <b-form-input id="password" v-model="form.user.password" placeholder="Enter password" type="password" required></b-form-input>
          </b-form-group>

          <b-form-checkbox id="remember_me" v-model="form.user.remember_me" name="remember_me">
            Remember me
          </b-form-checkbox>

          <b-button type="submit" variant="primary">Login</b-button>
        </b-form>
      </div>
    </div> -->
    <div class="wrapper">
        <div class="logo">
            <img src="../../../assets/images/logos/cperi.png" alt="" >
        </div>
        <div class="text-center mt-4 name">
            Welcome
        </div>
        <FlashMessages />
        <form class="p-3 mt-3" @submit.prevent="form.post($routes.user_session())">
            <div class="form-field d-flex align-items-center">
                <span class="far fa-user"></span>
                <b-form-input id="email" v-model="form.user.email" type="email" placeholder="Enter email" required></b-form-input>
            </div>
            <div class="form-field d-flex align-items-center">
                <span class="fas fa-key"></span>
                <b-form-input id="password" v-model="form.user.password" placeholder="Enter password" type="password" required></b-form-input>
            </div>
            <div class="text-center fs-6">
          <b-form-checkbox class="checkbox" id="remember_me" v-model="form.user.remember_me" name="remember_me">
            Remember me
          </b-form-checkbox>
        </div>
            <button class="btn mt-3">Login</button>
        </form>
    </div>
    <nav class="navbar fixed-bottom navbar-dark bg-dark footer">
    <p>Copyright © Certh/CPERI</p>
    <img class="imgFooter" src="../../../assets/images/logos/transparent/logo-eu-new2.png" alt="" srcset="" width="200" height="60">
  </nav>
  </div>
</template>

<script>
import FlashMessages from '@/Shared/FlashMessages'
export default {
  components: {
    FlashMessages
  },
  data() {
    return {
      form: this.$inertia.form({
        user: {
          email: '',
          password: '',
          remember_me: null,
        },
      }),
    }
  },
}
</script>

<style lang="css" scoped>
.login-form {
  width: 350px;
  margin: auto;
  background: white;
  color: black;
  padding: 25px;
  border-radius: 5px;
  margin-top: auto;
}
.navbar{
  opacity: 0.9;
}
.login-container {
  min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh; /* These two lines are counted as one :-)       */
  display: flex;
  align-items: center;
}
.login{
  color:white;
  background-image: url("../../../assets/images/logos/beautiful-milky-way-night-sky.jpg");
  background-size: cover;
  position:fixed;
  width:100%;
  height:100%;
  overflow: auto;
  top:0px;
  left:0px;
  z-index:1000;
  font-size: 20px;
}
.footer{
  padding-left: 10px;
  padding-right: 10px;
}

.wrapper {
    max-width: 350px;
    min-height: 500px;
    margin: 120px auto;
    padding: 40px 30px 30px 30px;
    background-color: #ecf0f3;
    border-radius: 15px;
    
}

.logo {
    width: 80px;
    margin: auto;
}

.logo img {
    width: 100%;
    height: 80px;
    object-fit: contain;
    border-radius: 20%;
    box-shadow: 0px 0px 3px #5f5f5f,
        0px 0px 0px 5px #ecf0f3,
        8px 8px 15px #a7aaa7,
        -8px -8px 15px #fff;
}

.wrapper .name {
    font-weight: 600;
    font-size: 1.4rem;
    letter-spacing: 1.3px;
    padding-left: 10px;
    color: #555;
}

.wrapper .form-field input {
    width: 100%;
    display: block;
    border: none;
    outline: none;
    background: none;
    font-size: 1.2rem;
    color: #666;
    padding: 10px 15px 10px 10px;
    /* border: 1px solid red; */
}

.wrapper .form-field {
    padding-left: 10px;
    margin-bottom: 20px;
    border-radius: 20px;
    box-shadow: inset 8px 8px 8px #cbced1, inset -8px -8px 8px #fff;
}

.wrapper .form-field .fas {
    color: #555;
}

.wrapper .btn {
    box-shadow: none;
    width: 100%;
    height: 40px;
    background-color: #03A9F4;
    color: #fff;
    border-radius: 25px;
    box-shadow: 3px 3px 3px #b1b1b1,
        -3px -3px 3px #fff;
    letter-spacing: 1.3px;
}

.wrapper .btn:hover {
    background-color: #039BE5;
}

.wrapper a {
    text-decoration: none;
    font-size: 0.8rem;
    color: #03A9F4;
}
.wrapper .checkbox{
  color:#666
}

@media (max-width: 1300px){
  .navbar{
    zoom:70%;
  }
  .wrapper{
    zoom:65%;
  }
}
</style>
