<template>
  <div>
    <b-row>
      <b-col>
        <h2>PSIs Analysis</h2>
      </b-col>
    </b-row>
    <hr>
    <b-row v-if="pythonErrors">
      <b-col>
        <b-list-group class="mb-2">
          <b-list-group-item variant="danger">
            {{ pythonErrors.errors }}
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
    <b-row v-else class="justify-content-between">
      <b-col cols="12 mb-3">
        <SimpleVerticalBarChart
            v-if="callImpactData && callImpactData.labels.length > 0"
            :chartData="callImpactData"
            :gradientColors="callImpactGradientColors"
            :axisLabels="{ x: 'Call Impact Id', y: 'Call Impact Score' }"
            :title="`Call Impact Data`"
            id="psi1"
        >
        </SimpleVerticalBarChart>
      </b-col>
      <b-col cols="12 mb-3">
        <SimpleBarChart
            v-if="psisData"
            :chartData="psisData"
            :gradientColors="psisGradientColors"
            :averageValue="averageValue"
            title="PSIs Scores"
            :axisLabels="{x: 'Score', y: ''}"
            :key="`bar_key_${horizontalBarKey}`"
            id="psi2"
        >
        </SimpleBarChart>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <inertia-link
            :href="this.$routes.project(projectId)"
        >Back to project</inertia-link>
      </b-col>
    </b-row>
    <hr>
  </div>
</template>

<script>
import MainLayout from '@/Layouts/Main'
import BarChart from "@/Charts/base/BarChart";
import HorizontalBarChart from "@/Charts/base/HorizontalBarChart";
import SimpleBarChart from "@/Charts/simple/SimpleBarChart";
import SimpleVerticalBarChart from "@/Charts/simple/SimpleVerticalBarChart";

export default {
  name: "PsisAnalysis",
  components: {
    BarChart,
    HorizontalBarChart,
    SimpleBarChart,
    SimpleVerticalBarChart
  },
  layout: MainLayout,
  props: {
    result: {
      type: Object,
      required: false
    },
    callImpactData: {
      type: Object,
      required: false
    },
    callImpactGradientColors: {
      type: Array,
      required: false
    },
    psisData: {
      type: Object,
      required: false
    },
    averageValue: {
      type: Number,
      required: false,
      default: null
    },
    psisGradientColors: {
      type: Array,
      required: false
    },
    projectId: {
      type: Number,
      required: true
    },
    pythonErrors: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      horizontalBarKey: 1,
      psisOptions: {
        scales: {
          xAxes: [{
            ticks: {
              beginAtZero: true,
              suggestedMax: 5.0
            }
          }]
        }
      }
    }
  },
  computed: {
    // Create a deep copy of data. I don't know if this is cool but it throws an error using it "naked"
    myChartData: function () {
      return this.deepCopy(this.callImpactData)
    },
    psisDataCopy: function () {
      return this.deepCopy(this.psisData)
    }
  },
  mounted () {
    Chart.defaults.global.defaultFontColor = 'white'
    const obj = this
    window.addEventListener('resize', function() {
      if (obj.horizontalBarKey === 1) obj.horizontalBarKey = 0
      else obj.horizontalBarKey = 1
    })
  }
}
</script>

<style scoped>

</style>