<template>
  <div>
    <b-table
        :items="kpiTypes"
        :fields="fields"
        responsive="sm"
        striped
        hover
        sticky-header="95vh"
        class="indigo-header-table"
    >
      <template #cell(name)="data">
        {{ clearAndCapitalize(data.item.name) }}
      </template>

      <template #cell(functional_unit)="data">
        {{ clearAndCapitalize(data.item.functional_unit) }}
      </template>
    </b-table>
  </div>
</template>

<script>
import MainLayout from '@/Layouts/Main'
import StringManipulation from "@/Mixins/StringManipulation";
export default {
  name: "Index",
  layout: MainLayout,
  mixins: [StringManipulation],
  props: {
    kpiTypes: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      fields: ['name', 'functional_unit'],
    }
  }
}
</script>

<style scoped>

</style>