<template>
  <div class="form-step" no-gutters>
    <b-row class="mb-2">
      <b-col cols="6">
        <b-form-group
            id="psi_target_value_group"
        >
          <template v-slot:label>
            <label>Target Value <InfoIcon message="Target value of the PSI in functional unit."></InfoIcon></label>
          </template>
          <b-form-input
              id="psi_target_value"
              v-model="form.target_value"
              type="number"
              step="0.01"
              placeholder="Enter target value"
              required
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" class="mb-2">
        <b-form-checkbox
            id="relative_margins"
            v-model="form.relative_margins"
            name="relative_margins"
            value="true"
            unchecked-value="false"
        >
          Use relative margins? <InfoIcon message="Check if margins are percentages of the target value."></InfoIcon>
        </b-form-checkbox>
      </b-col>
      <hr>
      <b-row>
        <b-col cols="12">
          <div style="margin-bottom: 15px;">
            <span class="text-muted">Provide the margins</span>
            <InfoIcon message="The values of the margins must be in ascending or descending order."></InfoIcon>
          </div>
        </b-col>
        <b-col cols="4" v-for="index in [0, 1, 2, 3]" class="mb-2" :key="`m${index}`">
          <b-form-group
              :id="`psi_m${index}_input`"
              :label="getLabel(index)"
              :label-for="`kpi_m${index}`"
              :style="getColor(index)"
              class="likert-input"
          >
            <b-form-input
                :id="`psi_m${index}`"
                v-model="form[`m${index}`]"
                type="number"
                placeholder="Enter margin"
                step="0.001"
                min="-10000.00"
                max="10000.00"
                required
            ></b-form-input>
          </b-form-group>
        </b-col>

        <b-col cols="4" class="mb-3">
          <b-form-group
              :id="`kpi_m${4}_input`"
              :label="getLabel(4)"
              :label-for="`kpi_m${4}`"
              :style="getColor(4)"
              class="likert-input"
          >
            <b-form-input
                :id="`kpi_m${4}`"
                :value="m4"
                type="text"
                placeholder="autocompleted"
                readonly
            ></b-form-input>
          </b-form-group>
        </b-col>
        <div v-if="monotonous === 'error'" style="color: red">Wrong margins</div>

        <MarginsDistributionPlot
            v-if="monotonous === 'asc' || monotonous === 'desc'"
            :values="[form.m0, form.m1, form.m2, form.m3]"
            :isPercentage="percentageMargins"
        ></MarginsDistributionPlot>
        <span v-else>The distribution plot will be generated after you have given all margin inputs.</span>
      </b-row>
     </b-row>
  </div>
</template>

<script>
import MarginsDistributionPlot from "@/Shared/MarginsDistributionPlot";

export default {
  name: "SecondStep",
  components: {
    MarginsDistributionPlot
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    m4 () {
      if (this.monotonous === 'asc') return `>= ${this.form.m3}`
      else if (this.monotonous === 'desc') return `<= ${this.form.m3}`
      else return null
    },
    monotonous () {
      const psi = this.form
      return this.isMonotonic([psi.m0, psi.m1, psi.m2, psi.m3])
    },
    percentageMargins () {
      return this.form.relative_margins
    }
  },
  methods: {
    getLabel(index) {
      if (index === 0)
        return 'Failed'
      else if (index === 1)
        return 'Underachieved'
      else if (index === 2)
        return 'Achieved'
      else if (index === 3)
        return 'Overachieved'
      else
        return 'Excelled'
    },
    getColor(index) {
      if (index === 0)
        return 'background: rgb(255 72 72);'
      else if (index === 1)
        return 'background: #ffa700;'
      else if (index === 2)
        return 'background: #fff400;'
      else if (index === 3)
        return 'background: rgb(125 195 0);'
      else
        return 'background: #2cba00;'
    }
  }
}
</script>

<style scoped>

</style>