<template>
    <b-form-checkbox
            v-model="isEnabled"
            @change="updateSelectedData"
        > Include Final {{ averageType }} Scores</b-form-checkbox>
</template>

<script>
    export default {
        props: {
            enabledSiiScores: {
                type:Boolean,
                default:false,
                required: false
            },
             isSpiOrSii: {
                type: String,
                required: false,
                default: 'sii'
            }
        },
        data(){
            return{
                isEnabled: false
            }
        },
        methods: {
            updateSelectedData(){
                this.$emit('update-data',this.isEnabled);
            }
        },
        computed: {
            averageType () {
                if (this.isSpiOrSii === 'sii') return 'SII'
                else return 'SPI'
            }
        },
        watch: {
            enabledSiiScores(){
                this.isEnabled = this.$props.enabledSiiScores
            }
        }
    }
</script>

<style scoped>

</style>