<template>
  <div class="select-chart-container" :id="id">
    <b-row>
      <b-col cols="12">
        <ChartTitle :title= title></ChartTitle>
      </b-col>
    </b-row>

    <b-col v-if="showColorCode" cols="12">
      <AverageScore 
              colorTable ="gradientColors"
              :gradientColors="gradientColors"
              ></AverageScore> 
    </b-col>

    <b-col cols="12">
      <BarChart
          v-if="chartData"
          :chartData="deepCopy(chartData)"
          :options="options"
          :aspectRatio="aspectRatio"
          class="bar-chart-plot-full-width"
      >
      </BarChart>
    </b-col>
    <b-button @click="printChart(id)" style="margin-left:10px">Download graph</b-button>
  </div>
</template>

<script>
import BarChart from "../base/BarChart";
import ChartTitle from "../components/ChartTitle";
import AverageScore from "../components/AverageScore";
import Exporter from "vue-chartjs-exporter";

export default {
  name: "SimpleVerticalBarChart",
  components: {
    BarChart,
    ChartTitle,
    AverageScore,
    Exporter
  },
  props: {
    title: {
      type: String,
      default: null,
      required: false
    },
    chartData: {
      type: Object,
      required: true
    },
    gradientColors: {
      type: Array,
      required: true
    },
    showColorCode: {
      type: Boolean,
      required: false,
      default: true
    },
    axisLabels: {
      type: Object,
      required: false,
      default: function () {
        return {x: '', y: ''}
      },
    },
    aspectRatio: {
      type: Object,
      required: false,
      default: function () {
        return { width: 3, height: 1 }
      }
    },
    showChartLegend: {
      type: Boolean,
      required: false,
      default: false
    },
    id:{
      type: String,
      required: true
    }
  },
  computed: {
    options () {
      return {
        aspectRatio: 1,
        legend: {
          display: this.showChartLegend,
          labels: {
            fontSize: 14,
            fontColor: 'white'
          }
        },
        scales: {
          xAxes: [{
            ticks: {
              fontSize: 16,
              fontColor: 'white'
            },
            scaleLabel: {
              display: true,
              labelString: this.axisLabels.x,
              fontSize: 16,
              fontColor: 'orange',
            },
            barPercentage: 0.4
          }],
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: this.axisLabels.y,
              fontSize: 16,
              fontColor: 'orange'
            },
            ticks: {
              beginAtZero: true,
              max: 5,
              fontSize: 16,
              fontColor: 'white'
            }
          }]
        },
      }
    }
  },
  methods:{
  printChart(id)
  {
    //Disappear the button
    let graphical = document.getElementById(id);
    const exp = new Exporter([graphical]);
    exp.export_pdf().then((pdf) => pdf.save("charts.pdf"));
  }
}
}
</script>

<style scoped>

</style>