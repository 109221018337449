<template>
        <h5 v-if="title">{{ title }}</h5>
        <h5 v-else>Untitled Bar Chart</h5>
</template>

<script>
    export default {
        props:{
            title: {
                type: String,
                default: null,
                required: false
            }
        }
    }
</script>

<style scoped>
    h5{
        text-align: center;
        font-size: 22px;
    }
</style>