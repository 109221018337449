<template>
  <div class="manual-text-container">
    <h2>SII and SPI Analyses</h2>
    <p>The second and third analyses are called <b>S</b>ustainability <b>I</b>mpact <b>I</b>ndex (<b>SII</b>) and <b>S</b>ustainability <b>P</b>erformance <b>I</b>ndex
      (<b>SPI</b>) and both are based on <b>K</b>ey <b>P</b>erformance <b>I</b>ndicators (<b>KPIs</b>), which quantify the project results based on
      quantitative or qualitative data in order to assess how close cities are to meet their goals and provide a
      comprehensive analysis regarding smart and sustainable performance and progress. The difference between the two
      analyses is that the SII analysis is based on the categorization of KPIs in dimensions (also called domains or
      categories depending on the project), such as “Energy”, “Economic” and “Environmental”, which is usually done
      during the implementation of a smart city project, while the SPI analysis is based on the categorization of KPIs
      in pre-defined sectors, such as the “resource efficiency” and the “quality of life and prosperity”, which allows
      for a more holistic but also targeted evaluation of each project’s results into the specific sectors of interest,
      leading to a cross-dimensional evaluation.</p>
  </div>
</template>

<script>
export default {
  name: "SiiSpiAnalysis"
}
</script>

<style scoped>

</style>