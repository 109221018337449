<template>
  <table class="compare-table" v-if="projectsCompareList.length > 0">
    <thead>
    <tr>
      <th colspan="2">Projects to be compared</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="project in projectsCompareList" :key="project.id">
      <td>{{ project.name }}</td>
      <td
          class="remove-comparable-btn"
          @click="removeComparable(project.id)"
      >[x]</td>
    </tr>
    <tr v-if="projectsCompareList.length > 1">
      <td colspan="2" class="compare-btn">
        <inertia-link
            v-if="hasMoreThanOneProjects()"
            :href="this.$routes.projects_compare({'project1': getProject(0).id, 'project2': getProject(1).id})"
        >
          Compare
        </inertia-link>
      </td>
    </tr>
    <tr v-else>
      <td colspan="2" class="compare-btn">
        Add one more project to compare
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "ComparableProjectsList",
  props: {
    projectsCompareList: {
      type: Array,
      required: true
    }
  },
  methods: {
    getProject(index) {
      return this.projectsCompareList[index]
    },
    hasMoreThanOneProjects () {
      return this.projectsCompareList.length > 1
    },
    removeComparable(id) {
      const projectIndex = this.projectsCompareList.findIndex(p => p.id === id)
      console.log(projectIndex)
      this.projectsCompareList.splice(projectIndex, 1)
    }
  }
}
</script>

<style scoped>

</style>