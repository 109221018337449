<template>
  <div>
    <div style="width: 100%; height: 40px; border-bottom: 2px dotted midnightblue">
      <div :style="`width: ${edgesWidths * 100}%; background: rgb(255 71 72); border-right: 2px dashed darkblue;`" class="performance-bar round-corners-left">Failed</div>
      <div :style="`width: ${widths[0] * 80}%; background: rgb(255, 167, 0);border-right: 2px dashed darkblue;`" class="performance-bar">Underachieved</div>
      <div :style="`width: ${widths[1] * 80}%; background: rgb(255, 244, 0);border-right: 2px dashed darkblue;`" class="performance-bar">Achieved</div>
      <div :style="`width: ${widths[2] * 80}%; background: rgb(125, 195, 0);border-right: 2px dashed darkblue;`" class="performance-bar">Overachieved</div>
      <div :style="`width: ${edgesWidths * 100}%; background: rgb(44, 186, 0);`" class="performance-bar round-corners-right">Excelled</div>
    </div>
    <div style="width: 100%; height: 40px;">
      <div :style="`width: ${edgesWidths * 100}%; background: rgb(255 71 72); border-right: 2px dashed darkblue;`" class="performance-bar"><-- 1 --></div>
      <div :style="`width: ${widths[0] * 80}%; background: rgb(255, 167, 0);border-right: 2px dashed darkblue;`" class="performance-bar"><-- 2 --></div>
      <div :style="`width: ${widths[1] * 80}%; background: rgb(255, 244, 0);border-right: 2px dashed darkblue;`" class="performance-bar"><-- 3 --></div>
      <div :style="`width: ${widths[2] * 80}%; background: rgb(125, 195, 0);border-right: 2px dashed darkblue;`" class="performance-bar"><-- 4 --></div>
      <div :style="`width: ${edgesWidths * 100}%; background: rgb(44, 186, 0);`" class="performance-bar"><-- 5 --></div>
    </div>
    <div style="width: 100%; height: 30px;" class="performance-bar-margins-container">
      <div :style="`width: ${(edgesWidths + widthSpacing) * 100}%;`" class="performance-bar-margins">{{ values[0] }}<span v-if="isPercentageWatcher">%</span></div>
      <div :style="`width: ${widths[0] * 80}%;`" class="performance-bar-margins"> {{ values[1] }}<span v-if="isPercentageWatcher">%</span></div>
      <div :style="`width: ${widths[1] * 80}%;`" class="performance-bar-margins">{{ values[2] }}<span v-if="isPercentageWatcher">%</span></div>
      <div :style="`width: ${widths[2] * 80}%;`" class="performance-bar-margins">{{ values[3] }}<span v-if="isPercentageWatcher">%</span></div>
    </div>
  </div>
</template>

<script>
import MarginsFunctions from "@/Mixins/MarginsFunctions";

export default {
  name: "MarginsDistributionPlot",
  mixins: [MarginsFunctions],
  props: {
    values: {
      type: Array,
      required: true
    },
    isPercentage: {
      type: String,
      required: false,
      default: 'false'
    }
  },
  data () {
    return {
      edgesWidths: 0.1
    }
  },
  computed: {
    isPercentageWatcher () {
      return this.isPercentage === 'true'
    },
    widths () {
      let arr = []
      const normalized = this.normalizeMargins(this.values)
      for (let i = 0; i < normalized.length - 1; i++) {
        let proportion = normalized[i + 1] - normalized[i]
        if (proportion < 0) proportion *= -1
        arr.push(proportion)
      }
      return arr
    },
    // when the margins are greater than 10
    // then they should be pushed a little to be under the dashed lines
    // if they are less than 10 then no need for that
    widthSpacing () {
      if (this.values[0] > 9) return 0.01
      return 0
    }
  }
}
</script>

<style scoped>
  .performance-bar {
    text-align: center;
    padding-top: 8px;
    height: 40px;
    float: left;
    border-bottom: 1px dotted midnightblue;
  }

  .round-corners-left {
    border-radius: 10px 0 0 0;
  }

  .round-corners-right {
    border-radius: 0 10px 0 0;
  }

  .performance-bar-margins-container {
    font-size: 18px;
    background: #505050;
    color: white;
  }
  .performance-bar-margins {
    text-align: right;
    float: left;
    height: 30px;
  }
</style>