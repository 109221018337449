<template>
  <transition name="fade">
    <div v-if="isOpen" style="border-radius: 5px; margin: 10px; padding: 5px; position: relative; background: #ffffff;">
      <button @click="onCloseBtn" class="x-btn">x</button>
      <div
          @click="onShowClick"
          style="padding: 5px; border-radius: 5px; display: inline-block; color: #2b2b2b; text-decoration: underline; cursor: pointer;"
      >
        Click here to set ignored weights <InfoIcon message="Select the weights that will be ignored."></InfoIcon>
      </div>
      <transition name="fade">
        <div v-if="successStr !== ''" style="padding-left: 5px"><i>{{ successStr }}</i></div>
      </transition>
      <transition name="fade">
        <div v-if="showForm">
          <div v-if="ignoreAll === 'not_all_weights'" v-for="(item, index) in selectedPairs" style="margin-bottom: 5px;">
            <b-form-select v-model="selectedPairs[index]['level']" :options="aggregationLevels" size="sm"
                           class="pair-select mt-3"></b-form-select>

            <b-form-select v-model="selectedPairs[index]['dimension']" :options="dimensions" size="sm"
                           class="pair-select mt-3"></b-form-select>
            <div  style="display: inline-block; padding-bottom: 2px;"><b-button class="remove-btn" @click="onItemRemove(index)">Remove</b-button></div>

          </div>
          <hr>
          <div>
            <b-form-checkbox
                id="checkbox-1"
                v-model="ignoreAll"
                name="checkbox-1"
                value="all_weights"
                unchecked-value="not_all_weights"
                @change="onIgnoreAll"
            >
                <span style="margin-left: 4px;">Ignore all weights</span>
            </b-form-checkbox>
          </div>
          <hr>
          <b-button @click="onAddPair"
               class="btn btn-sm btn-deep-blue mb-1 mt-2" style="margin-left: 5px;">Add pair</b-button>
          <b-button @click="onSubmit" variant="success" size="sm" class="mb-1 mt-2">Save Preferences</b-button>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
export default {
  name: "IgnoreWeightsSelector",
  props: {
    projectId: {
      type: Number,
      required: true
    },
    ignoredWeightsServer: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      aggregationLevels: [
        {value: '', text: 'Select an aggregation level'},
        {value: 'building', text: 'Building'},
        {value: 'ped', text: 'PED'},
        {value: 'peb', text: 'PEB'},
        {value: 'city', text: 'City'}
      ],
      dimensions: [
        {value: '', text: 'Select a dimension/sector'},
        // dimensions
        {value: '-', text: '-- Dimensions --', disabled: 'true'},
        {value: 'economic', text: 'Economic'},
        {value: 'energy', text: 'Energy'},
        {value: 'environmental', text: 'Environmental'},
        {value: 'governance', text: 'Governance'},
        {value: 'ict', text: 'ICT'},
        {value: 'mobility', text: 'Mobility'},
        {value: 'propagation', text: 'Propagation'},
        {value: 'social', text: 'Social'},
        // sectors
        {value: '-', text: '-- Sectors --', disabled: 'true'},
        {value: 'climate_change_&_mitigation', text: 'Climate Change & Mitigation'},
        {value: 'quality_of_life_&_prosperity', text: 'Quality of Life & Prosperity'},
        {value: 'resource_efficiency', text: 'Resource Efficiency'},
        {value: 'smart_&_reliable_infrastructure', text: 'Smart & Reliable Infrastructure'},
      ],
      // selectedPairs: [{dimension: '', level: ''}],
      showForm: false,
      successStr: '',
      isOpen: true,
      ignoreAll: 'not_all_weights'
    }
  },
  computed: {
    selectedPairs:{
      get () {
        if (this.ignoredWeightsServer.length === 0)
          this.ignoredWeightsServer.push({dimension: '', level: ''})
        return this.ignoredWeightsServer
      },
      set (value) {
        return value
      }
    }
  },
  methods: {
    onShowClick () {
      this.showForm = !this.showForm
    },
    onAddPair() {
      this.selectedPairs.push({dimension: '', level: ''})
    },
    onItemRemove (index) {
      if (this.selectedPairs.length > 0)
        this.selectedPairs.splice(index, 1);
      if (this.selectedPairs.length === 0)
        this.selectedPairs.push({dimension: '', level: ''})
    },
    onIgnoreAll () {
      // add all combinations in selected pairs
      if (this.ignoreAll === 'all_weights') {
        this.selectedPairs.splice(0, this.selectedPairs.length)
        for (let i = 0; i < this.aggregationLevels.length; i++) {
          const level = this.aggregationLevels[i].value
          if (level === '') continue
          for (let j = 0; j < this.dimensions.length; j++) {
            const dimension = this.dimensions[j].value
            if (dimension === '-' || dimension === '') continue
            this.selectedPairs.push({ dimension, level})
          }
        }
      } else {
        this.selectedPairs.splice(0, this.selectedPairs.length)
      }

    },
    onSubmit() {
      this.$inertia.post(this.$routes.ignore_weights(), {project_id: this.projectId, pairs: this.selectedPairs})
      this.selectedPairs = [{dimension: '', level: ''}]
      this.showForm = false
      this.successStr = 'Saved preferences'
    },
    onCloseBtn () {
      this.isOpen = false
    }
  },
  mounted () {
    // TODO: this is rather stupid way to check if ignore all is selected
    // should be a column in the db table
    if (this.selectedPairs.length === 49) {
      this.ignoreAll = 'all_weights'
    }
  }
}
</script>

<style scoped>

  .x-btn {
    display: inline-block;
    border-radius: 15%;
    background: transparent;
    position: absolute;
    right: 0;
    margin: 3px;
    border: none;
    width: 30px;
    height: 30px;
    font-size: 20px;
  }

  .pair-select {
    height: 30px;
    border-radius: 5px;
    background: ghostwhite;
  }

  .remove-btn {
    line-height: 1;
    padding: 0 10px 0 10px;
    height: 28px;
    margin: 0 0 5px;
    color: white;
    background: #f96362;
    border: none;
    font-size: 14px;
  }

 .fade-enter-active, .fade-leave-active {
   transition: opacity .3s;
 }
 .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
   opacity: 0;
 }
</style>