<template>
  <div>
    <h1 style="text-align: center; margin-bottom: 10px;">New PSI Creation</h1>
    <h2>{{ formSteps[currentFormStep] }}</h2>
    <hr>
    <b-row>
      <b-col>
        <b-form @submit.prevent="submit()">
          <FirstStep
              v-if="currentFormStep === 0"
              v-model="form"
              :psi-types="psiTypes"
              @name-changed="onNameChange"
          ></FirstStep>

          <SecondStep v-if="currentFormStep === 1" v-model="form"></SecondStep>

          <b-list-group v-if="messages.length > 0" class="mb-2">
            <b-list-group-item variant="danger" v-for="(message, index) in messages" :key="`message${index}`">
              {{ message }}
            </b-list-group-item>
          </b-list-group>

          <b-row>
            <b-col>
              <b-button
                  v-if="currentFormStep < 1"
                  type="button"
                  variant="primary"
                  @click="$event.target.blur()"
                  @click.prevent="proceed"
              >Next</b-button>
              <b-button v-if="currentFormStep > 0" type="button" @click.prevent="previous">Previous</b-button>
              <b-button v-if="currentFormStep === 1" typeof="button" variant="success" type="submit">Submit</b-button>
            </b-col>

            <b-col>
              <inertia-link :href="this.$routes.project(this.projectId)">
                <b-button
                    class="float-end"
                >Cancel</b-button>
              </inertia-link>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>
  </div>

</template>

<script>
import FirstStep from "./FormSteps/FirstStep";
import SecondStep from "./FormSteps/SecondStep";
export default {
  name: "Form",
  components: {
    FirstStep,
    SecondStep
  },
  props: {
    projectId: {
      type: Number,
      required: true
    },
    value: {
      type: Object,
      required: true
    },
    psiTypes: {
      value: Array,
      required: true
    },
    defaultPsiValues: {
      type: Object,
      required: false
    }
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  data () {
    return {
      currentFormStep: 0,
      formSteps: ['PSI & Call Impact Selection', 'Margins'],
      messages: []
    }
  },
  methods: {
    proceed () {
      if (this.currentFormStep === 0) this.proceedFirstStep()

      if (this.messages.length === 0)
        this.currentFormStep += 1
    },
    previous() {
      this.currentFormStep -= 1
    },
    proceedFirstStep() {
      this.messages = []
      if (this.form.name == null) this.messages.push('Empty PSI name.')
      // if (this.form.call_impact === null) this.messages.push('Empty Call Impact name.')
      if (!this.form.value) this.messages.push('Empty value.')
    },
    proceedSecondStep () {
      this.messages = []
      if (!this.form.target_value) this.messages.push('Empty target value.')

      if (this.isMonotonic([this.form['m0'],
        this.form['m1'], this.form['m2'], this.form['m3']]) === 'error')
        this.messages.push('Please provide correct margins.')
    },
    submit () {
      this.messages = []
      this.proceedSecondStep()

      if (this.messages.length === 0) {
        this.$emit('submit')
      }
    },
    onNameChange($event) {
      if (this.defaultPsiValues) {
        const name = $event
        const psi = this.form
        const defaultValues = this.defaultPsiValues[name]
        console.log(defaultValues)
        psi.value = defaultValues['value']
        psi.call_impact = defaultValues['call_impact']
        psi.target_value = defaultValues['target_value']
        psi.relative_margins = defaultValues['relative_margins']
        psi.label = ""
        psi.m0 = defaultValues['m0']
        psi.m1 = defaultValues['m1']
        psi.m2 = defaultValues['m2']
        psi.m3 = defaultValues['m3']
      }
    }
  },
  mounted () {

  }
}
</script>

<style scoped>

</style>