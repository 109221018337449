<template>
  <div>
    <p>This page will contain useful information on how to use the application.
    </p>
    <b-list-group>
      <b-list-group-item
          v-for="(item, index) in docs2"
          :class="{ 'manual-section-level-2': item.level === 2 }"
          :key="`doc_${index}`"
          :active="selected[index]"
          @click="selectItem(item, index)"
      >
        {{ item.name }}
      </b-list-group-item>
    </b-list-group>
    <hr>
    <div v-if="selectedItem">
      <h3>{{ selectedItem.title }}</h3>
      <p>{{ selectedItem.text }}</p>
    </div>
    <div v-else>
      <span>Please select an item from the list.</span>
    </div>
    <Intro v-if="selectedItem === 'Intro'"></Intro>
    <PpiAnalysis v-else-if="selectedItem === 'PPI Analysis'"></PpiAnalysis>
    <NewPsi v-else-if="selectedItem==='Creation of New PSI'"></NewPsi>
    <PpiResults v-else-if="selectedItem==='PPI Analysis Results'"></PpiResults>
    <SiiSpiAnalysis v-else-if="selectedItem==='SII and SPI Analyses'"></SiiSpiAnalysis>
    <NewKpi v-else-if="selectedItem==='Creation of New KPI'"></NewKpi>
    <SiiSpiResults v-else-if="selectedItem==='SII and SPI Analyses Results'"></SiiSpiResults>
  </div>
</template>

<script>
import Main from "../../Layouts/Main";
import Intro from './Intro'
import PpiAnalysis from "./PpiAnalysis";
import NewPsi from "./NewPsi";
import PpiResults from "./PpiResults";
import SiiSpiAnalysis from "./SiiSpiAnalysis";
import NewKpi from "./NewKpi";
import SiiSpiResults from "./SiiSpiResults";

export default {
  name: "Index",
  components: {
    Intro,
    PpiAnalysis,
    NewPsi,
    PpiResults,
    SiiSpiAnalysis,
    NewKpi,
    SiiSpiResults
  },
  layout: Main,
  data() {
    return {
      docs2: [
        {name: 'Intro', level: 1},
        {name: 'PPI Analysis', level: 1},
        {name: 'Creation of New PSI', level: 2},
        {name: 'PPI Analysis Results', level: 2},
        {name: 'SII and SPI Analyses', level: 1},
        {name: 'Creation of New KPI', level: 2},
        {name: 'SII and SPI Analyses Results', level: 2},
      ],
      docs: [
        {title: 'Purpose of the app', text: 'The purpose of the app is to evaluate KPIs and PSIs for proposals.'},
        {
          title: 'Basic functions',
          text: 'The basic functions of the app are: 1) Create a new project, 2) Add a new KPI, 3) Add a new PSI...'
        },
        {
          title: 'How to use',
          text: 'The user has to create an account. Then when they log in, they can create their projects and...'
        },
      ],
      selected: function () {
        let arr = []
        for (let i = 0; i < this.docs.length; i++) arr.push(false)
        return arr
      },
      selectedItem: ''
    }
  },
  methods: {
    selectItem(item, index) {
      this.selected = [false, false, false, false, false]
      this.selected[index] = true
      this.selectedItem = item.name
    }
  }
}
</script>

<style scoped>
.list-group-item {
  cursor: pointer;
}
</style>